import {
  AnchorHTMLAttributes,
  forwardRef,
  Ref,
  useEffect,
  useState,
} from 'react'

import { cn } from 'lib/utils/tailwind'

import { Icon } from '../Icon'
import { IconHolder } from './IconHolder'
import { ButtonBaseProps, StyledButton } from './OldButton'

export type ButtonLinkProps = AnchorHTMLAttributes<HTMLAnchorElement> &
  Omit<ButtonBaseProps, 'isLoading'>

export const ButtonLink = forwardRef(function ButtonLink(
  {
    variant = 'solid',
    size = 'default',
    isFull = false,
    leftIcon,
    rightIcon,
    children,
    ...props
  }: ButtonLinkProps,
  ref: Ref<HTMLAnchorElement>
) {
  // Prevents hydration error
  const [hasMounted, setHasMounted] = useState(false)

  useEffect(() => {
    setHasMounted(true)
  }, [])

  if (!hasMounted) {
    return null
  }

  return (
    <StyledButton
      as="a"
      isFull={isFull}
      size={size}
      variant={variant}
      ref={ref}
      {...props}
      className={cn(
        leftIcon && size === 'small' && children
          ? 'px-3'
          : leftIcon && size === 'default'
          ? 'px-4'
          : 'px-5'
      )}
    >
      <div className="flex items-center justify-center gap-2">
        {leftIcon && (
          <Icon
            icon={leftIcon}
            size={20}
            className={cn('w-fit', leftIcon === 'external' && 'opacity-60')}
          />
        )}

        {children}

        {rightIcon && (
          <IconHolder size="1.25rem">
            <Icon icon={rightIcon} size={20} />
          </IconHolder>
        )}
      </div>
    </StyledButton>
  )
})
