export const isSourceType = (
  filename: string,
  type: 'video' | 'image' | 'pdf'
): boolean => {
  const extensions = {
    video: ['m4v', 'avi', 'mpg', 'mp4', 'webm'],
    image: ['jpg', 'jpeg', 'gif', 'bmp', 'png', 'webp'],
    pdf: ['pdf'],
  }
  const fileExt = filename.split('.').pop()

  if (!fileExt) {
    throw new Error('filename should be a valid Source')
  }

  return extensions[type].includes(fileExt)
}
