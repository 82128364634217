import { motion, AnimatePresence } from 'framer-motion'
import { useState } from 'react'

import { CallToActionStoryblok } from 'lib/storyblok/types'
import { cn } from 'lib/utils/tailwind'

import { Icon } from './Icon'

export interface AccordionItem {
  title: string
  content: React.ReactNode
  ctas?: CallToActionStoryblok[]
}

interface AccordionProps {
  items: AccordionItem[]
}

export const Accordion = ({ items }: AccordionProps) => {
  const [openIndex, setOpenIndex] = useState<number | null>(null)

  const handleClick = (index: number) => {
    setOpenIndex(openIndex === index ? null : index)
  }

  return (
    <div className="space-y-4">
      {items.map((item, index) => (
        <div
          key={index}
          className="rounded-5 border border-foreground/5 bg-foreground/5"
        >
          <button
            id={`accordion-button-${index}`}
            onClick={() => handleClick(index)}
            aria-expanded={openIndex === index}
            aria-controls={`accordion-content-${index}`}
            className="w-full p-5 text-left font-medium flex justify-between items-center"
          >
            <span className="text-foreground text-twenty font-regular mr-4">
              {item.title}
            </span>

            <Icon
              size={24}
              aria-hidden="true"
              icon="caret-down"
              className={cn(
                'text-foreground/80 transition-all duration-200 ease-in-out',
                openIndex === index && '-rotate-180'
              )}
            />
          </button>

          <AnimatePresence initial={false}>
            {openIndex === index && (
              <motion.div
                initial={{ height: 0, opacity: 0 }}
                animate={{ height: 'auto', opacity: 1 }}
                exit={{ height: 0, opacity: 0 }}
                transition={{ duration: 0.2 }}
                className="overflow-hidden"
                id={`accordion-content-${index}`}
                role="region"
                aria-labelledby={`accordion-button-${index}`}
              >
                <div className="px-5 pb-5 text-twenty text-foreground font-light">
                  {item.content}
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      ))}
    </div>
  )
}
