import { forwardRef } from 'react'
import {
  MARK_BOLD,
  MARK_LINK,
  MARK_TEXT_STYLE,
  MARK_UNDERLINE,
  NODE_HEADING,
  NODE_IMAGE,
  NODE_LI,
  NODE_OL,
  NODE_PARAGRAPH,
  NODE_QUOTE,
  NODE_UL,
  render,
} from 'storyblok-rich-text-react-renderer'

import {
  NewMediaCarouselStoryblok,
  RichTextCtasStoryblok,
  RichTextFaqsStoryblok,
  RichTextHighlightCardStoryblok,
  RichTextMediaStoryblok,
  RichTextMetricsStoryblok,
  RichTextQuoteStoryblok,
} from 'lib/storyblok/types'
import { cn } from 'lib/utils/tailwind'

import { StoryblokCallToAction } from '../StoryblokCallToAction'
import { DynamicBlock } from '../sections/DynamicBlock'
import { NewMediaCarousel } from '../sections/NewMediaCarousel'
import { RichTextFaqs } from './RichTextFAQS'
import { RichTextHighlightCard } from './RichTextHighlightCard'
import { RichTextMedia } from './RichTextMedia'
import { RichTextMetrics } from './RichTextMetrics'
import { RichTextQuote } from './RichTextQuote'

interface RichtextStoryblok {
  type: string
  content?: RichtextStoryblok[]
  marks?: RichtextStoryblok[]
  attrs?: unknown
  text?: string
  [k: string]: unknown
}

type Resolvers = Record<string, object>

type StoryblokRichTextProps = React.HTMLAttributes<HTMLDivElement> & {
  document: RichtextStoryblok
  resolvers?: {
    node?: Resolvers
    mark?: Resolvers
    blok?: Resolvers
  }
}

const resolverAliases = new Map([
  ['p', NODE_PARAGRAPH],
  ['ul', NODE_UL],
  ['ol', NODE_OL],
  ['li', NODE_LI],
  ['h', NODE_HEADING],
  ['bold', MARK_BOLD],
  ['underline', MARK_UNDERLINE],
])

const parseResolvers = (unparsed: Resolvers) => {
  return Object.fromEntries(
    Object.entries(unparsed).map(([key, value]) => [
      resolverAliases.get(key) || key,
      value,
    ])
  )
}

export const RICH_TEXT_RESOLVERS = {
  node: {
    [NODE_PARAGRAPH]: (children: React.ReactNode) => {
      if (!children) {
        return null
      }

      return (
        <p className="[&:not(:first-child)]:mt-5 [&:not(:last-child)]:mb-5 [&_img]:rounded-5 [&_img]:mt-5 md:[&_img:first-child]:mt-10">
          {children}
        </p>
      )
    },
    [NODE_UL]: (children: React.ReactNode) => {
      if (!children) {
        return null
      }

      return (
        <ul
          className={cn(
            'relative space-y-2',
            '[&_ul]:mt-0 [&_ul]:mb-0 [&_ul]:pl-12',
            "[&>li]:before:content-[''] [&>li]:before:relative [&>li]:before:size-[0.15em] [&>li]:before:inline-block",
            '[&>li]:before:bg-current [&>li]:before:rounded-full',
            '[&>li]:before:align-middle [&>li]:before:mr-2 [&>li]:before:mb-[0.5em]'
          )}
        >
          {children}
        </ul>
      )
    },
  },
  mark: {
    bold: (children: React.ReactNode) => {
      if (!children) {
        return null
      }

      return (
        <strong className="font-regular text-foreground">{children}</strong>
      )
    },
  },
  blok: {
    'rich-text-ctas': (richTextCtas: RichTextCtasStoryblok) => {
      if (!richTextCtas) {
        return null
      }
      const { ctas } = richTextCtas

      return (
        <div className="flex gap-3">
          {ctas.map((cta) => (
            <StoryblokCallToAction cta={cta} key={cta._uid} />
          ))}
        </div>
      )
    },
  },
}

export const StoryblokRichText = forwardRef<
  HTMLDivElement,
  StoryblokRichTextProps
>(({ document, resolvers = {}, className, ...props }, ref) => {
  return (
    <div ref={ref} className={className} {...props}>
      {render(document, {
        nodeResolvers: {
          [NODE_HEADING]: (children, { level }) => {
            const Component = `h${level}` as React.ElementType

            return (
              <Component className="px-5 md:px-0 max-w-rich-text mx-auto my-5 md:mt-10 text-title-medium text-foreground font-regular">
                {children}
              </Component>
            )
          },
          [NODE_PARAGRAPH]: (children) => {
            if (!children) {
              return null
            }

            return (
              <p
                className={cn(
                  'px-5 md:px-0 max-w-rich-text mx-auto my-5 text-twenty',
                  '[&_b]:font-medium',
                  '[&_img]:rounded-4',
                  '[&_a]:text-foreground [&_a]:font-regular [&_a]:underline'
                )}
              >
                {children}
              </p>
            )
          },
          [NODE_UL]: (children) => {
            return (
              <ul
                className={cn(
                  'relative max-w-rich-text mx-auto my-5 px-5 md:px-0',
                  '[&_ul]:mt-0 [&_ul]:mb-0 [&_ul]:pl-4',
                  "[&>li]:flex-shrink-0 [&>li]:mb-3 [&>li]:ml-4 [&>li]:before:content-[''] [&>li]:before:size-1 [&>li]:before:align-middle [&>li]:before:mr-[0.4em] [&>li]:before:inline-block [&>li]:before:absolute [&>li]:before:top-3 [&>li]:before:-left-4",
                  '[&>li]:before:bg-foreground [&>li]:before:rounded-full'
                )}
              >
                {children}
              </ul>
            )
          },
          [NODE_OL]: (children) => {
            const items = children as React.ReactNode[]
            return (
              <ol
                className="relative max-w-rich-text mx-auto my-5 px-5 md:px-0"
                style={{ counterReset: 'item' }}
              >
                {items?.map((item, i) => {
                  return (
                    <span key={i} className="relative flex mb-3">
                      <span className="absolute -left-0 top-1.5 font-regular">
                        {i + 1}
                      </span>
                      <span className="ml-4">{item}</span>
                    </span>
                  )
                })}
              </ol>
            )
          },
          [NODE_LI]: (children) => {
            return (
              <li
                className={cn(
                  'relative',
                  '[&>p]:mb-0 [&>p]:pl-0 [&>*:first-child]:inline whitespace-pre-wrap'
                )}
                style={{ counterIncrement: 'item' }}
              >
                {children}
              </li>
            )
          },
          [NODE_IMAGE]: (_, props) => (
            <span className="w-full text-foreground flex flex-col gap-4 my-10 mx-auto max-w-rich-text">
              <img {...props} />
              <span className="flex text-sixteen text-foreground/80">
                {props.title}
              </span>
            </span>
          ),
          [NODE_QUOTE]: (children) => {
            return (
              <blockquote className="py-10 [&>p]:my-0 [&>p]:text-title-medium font-regular">
                {children}
              </blockquote>
            )
          },
          ...parseResolvers(resolvers.node ?? {}),
        },
        markResolvers: {
          [MARK_BOLD]: (children) => {
            return <strong className="font-regular">{children}</strong>
          },
          [MARK_LINK]: (children, link) => {
            return (
              <a
                className="underline font-regular text-foreground hover:cursor-pointer"
                href={link?.href}
                target={link?.target}
              >
                {children}
              </a>
            )
          },
          // ignore colors set by richtext editor in Storyblok
          [MARK_TEXT_STYLE]: (children) => {
            return <>{children}</>
          },
          ...parseResolvers(resolvers.mark ?? {}),
        },
        blokResolvers: {
          ['']: () => null,
          ['rich-text-quote']: (block) => (
            <RichTextQuote block={block as RichTextQuoteStoryblok} />
          ),
          ['rich-text-faqs']: (block) => {
            return <RichTextFaqs block={block as RichTextFaqsStoryblok} />
          },
          ['new-media-carousel']: (block) => {
            return (
              <div className="py-15">
                <NewMediaCarousel block={block as NewMediaCarouselStoryblok} />
              </div>
            )
          },
          ['rich-text-media']: (block) => (
            <RichTextMedia block={block as RichTextMediaStoryblok} />
          ),
          ['rich-text-highlight-card']: (block) => {
            return (
              <RichTextHighlightCard
                block={block as RichTextHighlightCardStoryblok}
              />
            )
          },
          ['rich-text-metrics']: (block) => {
            return <RichTextMetrics block={block as RichTextMetricsStoryblok} />
          },
          ['rich-text']: (block) => {
            return (
              <StoryblokRichText
                document={block.richtext as RichtextStoryblok}
              />
            )
          },
          ...parseResolvers(resolvers.blok ?? {}),
        },
        defaultBlokResolver: (name, props) => {
          const blok = { ...props, component: name }
          return <DynamicBlock block={blok} key={props._uid} />
        },
      })}
    </div>
  )
})
