import ContentLoader from 'react-content-loader'
import styled, { useTheme } from 'styled-components'

import { addAlphaToColor } from 'lib/style'

export const Loader = (): JSX.Element => {
  const theme = useTheme()

  return (
    <div className="pb-8 w-full">
      <MobileOnly>
        <ContentLoader
          backgroundColor={addAlphaToColor(theme.colors.foreground.subtle, 40)}
          foregroundColor={addAlphaToColor(theme.colors.foreground.subtle, 10)}
          opacity={0.25}
          viewBox="0 0 380 357"
        >
          <rect x="0" y="0" rx="4" ry="4" width="380" height="140" />
          <rect x="0" y="145" rx="1" ry="1" width="15" height="5" />
          <rect x="20" y="145" rx="1" ry="1" width="40" height="5" />
          <rect x="0" y="155" rx="2" ry="2" width="380" height="10" />

          <rect x="0" y="190" rx="4" ry="4" width="380" height="140" />
          <rect x="0" y="335" rx="1" ry="1" width="15" height="5" />
          <rect x="20" y="335" rx="1" ry="1" width="40" height="5" />
          <rect x="0" y="345" rx="2" ry="2" width="380" height="10" />
        </ContentLoader>
      </MobileOnly>
      <DesktopOnly>
        <ContentLoader
          backgroundColor={addAlphaToColor(theme.colors.foreground.subtle, 40)}
          foregroundColor={addAlphaToColor(theme.colors.foreground.subtle, 10)}
          opacity={0.25}
          viewBox="0 0 380 600"
        >
          <rect x="0" y="0" rx="4" ry="4" width="371" height="134" />

          <rect x="0" y="148" rx="4" ry="4" width="120" height="90" />
          <rect x="0" y="244" rx="1" ry="1" width="60" height="8" />
          <rect x="0" y="256" rx="1" ry="1" width="100" height="8" />

          <rect x="126" y="148" rx="4" ry="4" width="120" height="90" />
          <rect x="126" y="244" rx="1" ry="1" width="60" height="8" />
          <rect x="126" y="256" rx="1" ry="1" width="100" height="8" />

          <rect x="251" y="148" rx="4" ry="4" width="120" height="90" />
          <rect x="251" y="244" rx="1" ry="1" width="60" height="8" />
          <rect x="251" y="256" rx="1" ry="1" width="100" height="8" />

          <rect x="0" y="283" rx="4" ry="4" width="120" height="90" />
          <rect x="126" y="283" rx="4" ry="4" width="120" height="90" />
          <rect x="251" y="283" rx="4" ry="4" width="120" height="90" />
        </ContentLoader>
      </DesktopOnly>
    </div>
  )
}

const MobileOnly = styled.div`
  ${({ theme }) => theme.media.md} {
    display: none;
  }
`

const DesktopOnly = styled.div`
  display: none;

  ${({ theme }) => theme.media.md} {
    display: block;
  }
`
