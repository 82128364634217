import { useEffect } from 'react'

import useDrawer from './useDrawer'

// attach event listeners to anchor elements
export const useDrawerLinks = (node: HTMLElement | null) => {
  const { open } = useDrawer()

  useEffect(() => {
    if (!node) return

    const createHandleClick =
      (el: HTMLAnchorElement) => (event: MouseEvent) => {
        // meta key pressed. we can ignore to open in new tab
        if (event.metaKey) return

        const url = new URL(el.href)

        // check if the link is internal
        if (url.origin === window.location.origin) {
          event.preventDefault()
          open(url.pathname)
        }
      }

    const links = node.querySelectorAll('a[href]')

    links.forEach((link) => {
      if (link instanceof HTMLAnchorElement) {
        link.addEventListener('click', createHandleClick(link), true)
      }
    })

    return () => {
      links.forEach((link) => {
        if (link instanceof HTMLAnchorElement) {
          link.removeEventListener('click', createHandleClick(link), true)
        }
      })
    }
  }, [node, open])
}

export default useDrawerLinks
