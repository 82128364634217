import { cn } from 'lib/utils/tailwind'

export const Spinner = ({
  className,
}: React.HTMLAttributes<HTMLDivElement>) => (
  <svg
    aria-label="loading"
    role="progressbar"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={cn('relative animate-spin size-5 text-foreground', className)}
  >
    <path
      d="M11 19.8C15.8601 19.8 19.8 15.8601 19.8 11C19.8 6.13991 15.8601 2.20001 11 2.20001C6.13985 2.20001 2.19995 6.13991 2.19995 11"
      stroke="currentColor"
      stroke-width="1.54"
      stroke-linecap="round"
    />
  </svg>
)

Spinner.displayName = 'Spinner'
