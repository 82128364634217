import { FAQPageJsonLd } from 'next-seo'
import { ISbStoryData } from 'storyblok-js-client'

import { Seo } from 'components/Seo'
import { Layout } from 'components/blocks/layout/Layout'
import { DynamicBlock } from 'components/blocks/sections/DynamicBlock'
import {
  FaqEntryStoryblok,
  PageStoryblok,
  FaqsStoryblok,
} from 'lib/storyblok/types'
import { richtextToString } from 'lib/utils/content'

type Props = {
  story: ISbStoryData<PageStoryblok>
}

// These are the old faqs that are still in the CMS
const getFaqs = (
  body: ISbStoryData<PageStoryblok>['content']['body']
): FaqEntryStoryblok[] => {
  if (!body || !body.length) return []
  const faqs: FaqEntryStoryblok[] = []

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body?.forEach((block: any) => {
    if (block.component === 'faqs') faqs.push(...(block as FaqsStoryblok).faqs)

    if (block.body) faqs.push(...getFaqs(block.body))
  })

  return faqs
}

export const StaticPage = ({ story }: Props): JSX.Element => {
  const faqs = getFaqs(story.content.body)

  return (
    <Layout>
      {faqs.length > 0 && (
        <FAQPageJsonLd
          mainEntity={faqs.map((faq) => ({
            questionName: faq.question,
            acceptedAnswerText: richtextToString(faq.answer),
          }))}
        />
      )}

      <Seo story={story} />

      {story.content?.body?.map((block) => (
        <DynamicBlock key={block._uid} block={block} />
      ))}
    </Layout>
  )
}
