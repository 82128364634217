import { storyblokEditable } from 'lib/storyblok/storyblokEditable'
import { RichTextMetricsStoryblok } from 'lib/storyblok/types'
import { cn } from 'lib/utils/tailwind'

type Props = {
  block: RichTextMetricsStoryblok
}

export const RichTextMetrics = ({ block }: Props) => {
  const { metrics } = block

  return (
    <div
      className="w-full max-w-rich-text gap-5 grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 py-10 px-5 md:px-0 mx-auto"
      {...storyblokEditable(block)}
    >
      {metrics.map((metricItem, i) => {
        const { value, metric } = metricItem

        return (
          <div
            className={cn(
              'p-4 space-y-5 rounded-3',
              i % 6 === 0 && 'bg-background-secondary-donation-light',
              i % 6 === 1 && 'bg-background-secondary-news-light',
              i % 6 === 2 && 'bg-background-secondary-advice-light',
              i % 6 === 3 && 'bg-background-secondary-treatments-light',
              i % 6 === 4 && 'bg-background-secondary-lgbtqia-light',
              i % 6 === 5 && 'bg-background-secondary-condition-light'
            )}
            {...storyblokEditable(metricItem)}
          >
            <p className="text-title-medium font-regular">{value}</p>
            <p className="text-sixteen leading-tighter">{metric}</p>
          </div>
        )
      })}
    </div>
  )
}
