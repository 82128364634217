import { Button } from 'components/ui'
import { getValidIcon } from 'components/ui/Icon'
import { storyblokEditable } from 'lib/storyblok'
import { CallToActionStoryblok } from 'lib/storyblok/types'
import { getAnchorFromCmsLink } from 'lib/utils/content'

type Props = {
  cta: CallToActionStoryblok
}
export const StoryblokCallToAction = ({ cta }: Props) => {
  const { variant, icon, icon_position, label, alternative_text, link } = cta

  const typedIcon = getValidIcon(icon)
  const { href, target, rel } = getAnchorFromCmsLink(link)

  return (
    <Button
      as="a"
      rel={rel}
      href={href}
      target={target}
      icon={typedIcon}
      variant={variant}
      iconPosition={icon_position}
      alternativeText={alternative_text}
      {...storyblokEditable(cta)}
    >
      {label}
    </Button>
  )
}
