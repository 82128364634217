import { cva } from 'class-variance-authority'
import {
  Children,
  createContext,
  forwardRef,
  isValidElement,
  useContext,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'

import { composeRefs } from 'lib/utils/composeRefs'
import { cn } from 'lib/utils/tailwind'

const dateTypes = ['date', 'datetime-local', 'month', 'time', 'week']

export const inputStyle = cva([
  'w-full border px-2.5 transition focus:outline-none focus-visible:border-foreground/50 disabled:cursor-not-allowed disabled:opacity-60',
  'text-foreground/80 placeholder:text-foreground/60',
  'h-12 rounded-3 px-2.5 py-1 text-sixteen font-light leading-none bg-transparent',
  'border-foreground/20 hover:border-foreground',
  // TODO: 'data-[invalid]:border-accent-subtle data-[invalid]:hover:accent-subtle data-[invalid]:focus-visible:border-accent-subtle data-[invalid]:focus-visible:ring-accent-subtle/20',
])

interface InputProps
  extends Omit<React.ComponentPropsWithoutRef<'input'>, 'size'> {
  invalid?: boolean
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ className, ...props }, ref) => {
    const { prefixWidth, suffixWidth } = useContext(InputGroupContext)

    return (
      <input
        ref={ref}
        aria-describedby="input-description"
        aria-labelledby="input-label"
        className={cn(
          inputStyle(),
          prefixWidth > 0 && 'pl-10.5',
          suffixWidth > 0 && 'pr-3.5',
          props.type &&
            dateTypes.includes(props.type) && [
              '[&::-webkit-datetime-edit-fields-wrapper]:p-0',
              '[&::-webkit-date-and-time-value]:min-h-[1.5em]',
              '[&::-webkit-datetime-edit]:inline-flex',
              '[&::-webkit-datetime-edit]:p-0',
              '[&::-webkit-datetime-edit-year-field]:p-0',
              '[&::-webkit-datetime-edit-month-field]:p-0',
              '[&::-webkit-datetime-edit-day-field]:p-0',
              '[&::-webkit-datetime-edit-hour-field]:p-0',
              '[&::-webkit-datetime-edit-minute-field]:p-0',
              '[&::-webkit-datetime-edit-second-field]:p-0',
              '[&::-webkit-datetime-edit-millisecond-field]:p-0',
              '[&::-webkit-datetime-edit-meridiem-field]:p-0',
              '[&::-webkit-calendar-picker-indicator]:hidden',
            ],
          props.type === 'number' && [
            '[&::-webkit-inner-spin-button]:hidden',
            '[&::-webkit-outer-spin-button]:hidden',
            '[appearance:textfield]',
          ],
          className
        )}
        {...props}
      />
    )
  }
)

Input.displayName = 'Input'

type InputGroupProps = React.ComponentPropsWithoutRef<'div'>

interface InputGroupContextType {
  prefixWidth: number
  suffixWidth: number
  setPrefixWidth: (width: number) => void
  setSuffixWidth: (width: number) => void
}

const InputGroupContext = createContext<InputGroupContextType>({
  prefixWidth: 0,
  suffixWidth: 0,
  setPrefixWidth: () => {
    // noop
  },
  setSuffixWidth: () => {
    // noop
  },
})

export const InputGroup = forwardRef<HTMLDivElement, InputGroupProps>(
  ({ className, style, ...props }, ref) => {
    const [prefixWidth, setPrefixWidth] = useState(0)
    const [suffixWidth, setSuffixWidth] = useState(0)

    // check if there are more than one InputPrefix or InputSuffix
    const tooManyPrefixes =
      Children.toArray(props.children).filter(
        (child) => isValidElement(child) && child.type === InputPrefix
      ).length > 1

    const tooManySuffixes =
      Children.toArray(props.children).filter(
        (child) => isValidElement(child) && child.type === InputSuffix
      ).length > 1

    if (tooManyPrefixes || tooManySuffixes) {
      throw new Error(
        'InputGroup cannot have more than one InputPrefix or InputSuffix'
      )
    }

    return (
      <InputGroupContext.Provider
        value={{ prefixWidth, suffixWidth, setPrefixWidth, setSuffixWidth }}
      >
        <div
          className={cn('relative', className)}
          ref={ref}
          style={{
            ...style,
          }}
          {...props}
        />
      </InputGroupContext.Provider>
    )
  }
)

InputGroup.displayName = 'InputGroup'

interface InputPrefixProps extends React.ComponentPropsWithoutRef<'div'> {
  interactive?: boolean
}

export const InputPrefix = forwardRef<HTMLDivElement, InputPrefixProps>(
  (props, ref) => {
    const ctx = useContext(InputGroupContext)

    if (!ctx) {
      throw new Error('InputPrefix must be used within an InputGroup')
    }

    const { setPrefixWidth } = ctx

    return <InputAddon ref={ref} {...props} onSetWidth={setPrefixWidth} />
  }
)

InputPrefix.displayName = 'InputPrefix'

interface InputSuffixProps extends React.ComponentPropsWithoutRef<'div'> {
  interactive?: boolean
}

export const InputSuffix = forwardRef<HTMLDivElement, InputSuffixProps>(
  (props, ref) => {
    const ctx = useContext(InputGroupContext)

    if (!ctx) {
      throw new Error('InputSuffix must be used within an InputGroup')
    }

    const { setSuffixWidth } = ctx

    return <InputAddon ref={ref} {...props} onSetWidth={setSuffixWidth} />
  }
)

InputSuffix.displayName = 'InputSuffix'

interface InputAddonProps extends React.ComponentPropsWithoutRef<'div'> {
  onSetWidth?: (width: number) => void
  interactive?: boolean
}

const InputAddon = forwardRef<HTMLDivElement, InputAddonProps>(
  ({ className, onSetWidth, interactive, ...props }, ref) => {
    const internalRef = useRef<HTMLDivElement | null>(null)

    useLayoutEffect(() => {
      onSetWidth?.(internalRef.current?.offsetWidth ?? 0)

      const observer = new ResizeObserver(([entry]) => {
        if (entry?.contentRect.width) {
          onSetWidth?.(entry.contentRect.width)
        }
      })

      if (internalRef.current) {
        observer.observe(internalRef.current)
      }

      return () => observer.disconnect()
    }, [onSetWidth])

    return (
      <div
        data-input-addon
        className={cn(
          'absolute top-1/2 flex -translate-y-1/2 items-center justify-center text-sm',
          'first:left-3 last:right-3',
          interactive
            ? 'pointer-events-auto text-foreground-secondary transition hover:text-foreground'
            : 'pointer-events-none text-foreground',
          className
        )}
        ref={composeRefs(ref, internalRef)}
        {...props}
      />
    )
  }
)

InputAddon.displayName = 'InputAddon'
