import { cva } from 'class-variance-authority'
import { AnchorHTMLAttributes, forwardRef, Ref } from 'react'

import { cn } from 'lib/utils/tailwind'

import { IconOptions, Icon } from './Icon'
import { Spinner } from './Spinner'

export type NewLinkProps = AnchorHTMLAttributes<HTMLAnchorElement> & {
  icon?: IconOptions
  arrow?: boolean
  isLoading?: boolean
  isDisabled?: boolean
  variant?: 'primary' | 'secondary'
}

const linkVariants = cva(
  [
    'w-fit group inline-flex',
    'text-eighteen font-regular no-underline cursor-pointer',
    'hover:opacity-80',
    'outline-none rounded-2 focus-visible:ring-4 focus-visible:ring-foreground/20',
  ].join(' '),
  {
    variants: {
      variant: {
        primary: 'text-foreground active:text-accent-subtle',
        secondary: 'text-accent-secondary active:text-accent-dark',
      },
      arrow: {
        true: 'pe-1',
      },
      isLoading: {
        true: 'pointer-events-none',
      },
      isDisabled: {
        true: 'opacity-40 pointer-events-none',
      },
    },
    compoundVariants: [
      {
        isLoading: true,
        isDisabled: false,
        className: 'pointer-events-none',
      },
      {
        isDisabled: true,
        className: 'opacity-40 pointer-events-none',
      },
      {
        isLoading: true,
        arrow: true,
        className: 'pe-0',
      },
    ],
  }
)

export const Link = forwardRef(
  (
    {
      icon,
      arrow = false,
      isLoading = false,
      isDisabled = false,
      variant = 'primary',
      children,
      ...props
    }: NewLinkProps,
    ref: Ref<HTMLAnchorElement>
  ): JSX.Element => {
    return (
      <a
        ref={ref}
        {...props}
        aria-busy={isLoading}
        aria-disabled={isDisabled}
        className={linkVariants({ variant, arrow, isLoading, isDisabled })}
      >
        {icon && <Icon icon={icon} size={20} className="mr-2" />}

        <span
          className={cn(
            'inline-flex items-center line-clamp-1',
            arrow || isLoading ? 'mr-1' : ''
          )}
        >
          {children}
        </span>

        {arrow && !isLoading && (
          <Icon
            size={20}
            icon="arrow-right"
            className="mt-0.5 transition-transform duration-200 ease-in-out group-hover:translate-x-1 group-focus-visible:translate-x-1"
          />
        )}

        {isLoading && <Spinner />}
      </a>
    )
  }
)
