import dynamic from 'next/dynamic'
import { ComponentType } from 'react'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Components: Record<string, ComponentType<{ block: any }>> = {
  cards: dynamic(() => import('../deprecated/Cards').then((mod) => mod.Cards)),
  contacts: dynamic(() =>
    import('../deprecated/Contacts').then((mod) => mod.Contacts)
  ),
  'colored-section': dynamic(() =>
    import('../deprecated/ColoredSection').then((mod) => mod.ColoredSection)
  ),
  'container-section': dynamic(() =>
    import('../deprecated/ContainerSection').then((mod) => mod.ContainerSection)
  ),
  conversion: dynamic(() =>
    import('../deprecated/Conversion').then((mod) => mod.Conversion)
  ),
  'dynamic-form': dynamic(() =>
    import('./forms').then((mod) => mod.DynamicForm)
  ),
  'dynamic-form-steps': dynamic(() =>
    import('./forms').then((mod) => mod.DynamicFormSteps)
  ),
  faqs: dynamic(() => import('../deprecated/FAQS').then((mod) => mod.FAQS)),
  hero: dynamic(() => import('../deprecated/Hero').then((mod) => mod.Hero)),
  'dynamic-hero': dynamic(() =>
    import('../deprecated/DynamicHero').then((mod) => mod.DynamicHero)
  ),
  'clinic-search-hero': dynamic(() =>
    import('./heros/ClinicSearchHero').then((mod) => mod.ClinicSearchHero)
  ),
  'info-grid': dynamic(() =>
    import('../deprecated/InfoGrid').then((mod) => mod.InfoGrid)
  ),
  'rich-text': dynamic(() =>
    import('../deprecated/RichText').then((mod) => mod.RichText)
  ),
  'section-header': dynamic(() =>
    import('../deprecated/SectionHeader').then((mod) => mod.SectionHeader)
  ),
  media: dynamic(() =>
    import('../deprecated/MediaBlock').then((mod) => mod.MediaBlock)
  ),
  'media-carousel': dynamic(() =>
    import('../deprecated/MediaCarousel').then((mod) => mod.MediaCarousel)
  ),
  testimonial: dynamic(() =>
    import('../deprecated/Testimonial').then((mod) => mod.Testimonial)
  ),
  'selected-clinics': dynamic(() =>
    import('./SelectedClinics').then((mod) => mod.SelectedClinics)
  ),
  'selected-blog-posts': dynamic(() =>
    import('./SelectedBlogPosts').then((mod) => mod.SelectedBlogPosts)
  ),
  'selected-patient-stories': dynamic(() =>
    import('./SelectedPatientStories').then((mod) => mod.SelectedPatientStories)
  ),
  'text-highlight': dynamic(() =>
    import('../deprecated/TextHighlight').then((mod) => mod.TextHighlight)
  ),
  timeline: dynamic(() =>
    import('../deprecated/Timeline').then((mod) => mod.Timeline)
  ),
  'two-columns': dynamic(() =>
    import('../deprecated/TwoColumns').then((mod) => mod.TwoColumns)
  ),
  'all-entries': dynamic(() =>
    import('./AllEntries').then((mod) => mod.AllEntries)
  ),
  'latest-entries': dynamic(() =>
    import('./directories/LatestEntries').then((mod) => mod.LatestEntries)
  ),
  pricing: dynamic(() =>
    import('../deprecated/Pricing').then((mod) => mod.Pricing)
  ),
  'success-rates': dynamic(() =>
    import('../deprecated/SuccessRates').then((mod) => mod.SuccessRates)
  ),
  guestbook: dynamic(() =>
    import('../deprecated/Guestbook').then((mod) => mod.Guestbook)
  ),
  'column-layout': dynamic(() =>
    import('../deprecated/ColumnLayout').then((mod) => mod.ColumnLayout)
  ),
  steps: dynamic(() => import('../deprecated/Steps').then((mod) => mod.Steps)),
  'rich-text-button': dynamic(() =>
    import('../deprecated/RichTextButton').then((mod) => mod.RichTextButton)
  ),
  'rich-text-banner': dynamic(() =>
    import('../deprecated/RichTextBanner').then((mod) => mod.RichTextBanner)
  ),
  'iframe-embed': dynamic(() =>
    import('../deprecated/Iframe').then((mod) => mod.Iframe)
  ),
  'code-embed': dynamic(() =>
    import('./CodeEmbed').then((mod) => mod.CodeEmbed)
  ),
  table: dynamic(() => import('../deprecated/Table').then((mod) => mod.Table)),
  'clinic-locator': dynamic(() =>
    import(
      '../../../components/blocks/deprecated/ClinicLocator/ClinicLocator'
    ).then((mod) => mod.ClinicLocator)
  ),
  'table-of-contents': dynamic(() =>
    import('../deprecated/TableOfContents').then((mod) => mod.TableOfContents)
  ),
  'wrapper-anchor': dynamic(() =>
    import('./WrapperAnchor').then((mod) => mod.WrapperAnchor)
  ),
  events: dynamic(() => import('./Events').then((mod) => mod.Events)),
  usp: dynamic(() => import('./Usp').then((mod) => mod.Usp)),
  'success-rate': dynamic(() =>
    import('../deprecated/SuccessRate').then((mod) => mod.SuccessRate)
  ),
  'clinic-hero': dynamic(() =>
    import('./heros/ClinicHero').then((mod) => mod.ClinicHero)
  ),
  'success-rate-dynamic': dynamic(() =>
    import('./SuccessRateDynamic').then((mod) => mod.SuccessRateDynamic)
  ),
  'clinic-testimonial': dynamic(() =>
    import('./ClinicTestimonial').then((mod) => mod.ClinicTestimonial)
  ),
  'clinic-section-header': dynamic(() =>
    import('./ClinicSectionHeader').then((mod) => mod.ClinicSectionHeader)
  ),
  'new-media-carousel': dynamic(() =>
    import('./NewMediaCarousel').then((mod) => mod.NewMediaCarousel)
  ),
  'clinic-pricing-hero': dynamic(() =>
    import('./ClinicPricingHero').then((mod) => mod.ClinicPricingHero)
  ),
  'clinic-services': dynamic(() =>
    import('./ClinicServices').then((mod) => mod.ClinicServices)
  ),
  'clinic-navigation': dynamic(() =>
    import('./ClinicNavigation').then((mod) => mod.ClinicNavigation)
  ),
  'clinic-process': dynamic(() =>
    import('./ClinicProcess').then((mod) => mod.ClinicProcess)
  ),
  'team-carousel': dynamic(() =>
    import('./TeamCarousel').then((mod) => mod.TeamCarousel)
  ),
  'where-to-find-us': dynamic(() =>
    import('./WhereToFindUs').then((mod) => mod.WhereToFindUs)
  ),
  'service-header': dynamic(() =>
    import('../../../components/blocks/sections/ServiceHeader').then(
      (mod) => mod.ServiceHeader
    )
  ),
  'clinic-blog': dynamic(() =>
    import('./directories/ClinicBlog').then((mod) => mod.ClinicBlog)
  ),
  'link-cards-grid': dynamic(() =>
    import('./LinkCardsGrid').then((mod) => mod.LinkCardsGrid)
  ),
  'highlight-card': dynamic(() =>
    import('./HighlightCard').then((mod) => mod.HighlightCard)
  ),
  partnerships: dynamic(() =>
    import('./Partnerships').then((mod) => mod.Partnerships)
  ),
  'payment-section': dynamic(() =>
    import('./PaymentSection').then((mod) => mod.PaymentSection)
  ),
  refund: dynamic(() => import('./Refund').then((mod) => mod.Refund)),
  'pricing-section': dynamic(() =>
    import('./PricingSection').then((mod) => mod.PricingSection)
  ),
  'display-header': dynamic(() =>
    import('./DisplayHeader').then((mod) => mod.DisplayHeader)
  ),
  'rich-text-faqs': dynamic(() =>
    import('../rich-text/RichTextFAQS').then((mod) => mod.RichTextFaqs)
  ),
  'new-hero': dynamic(() =>
    import('./heros/NewHero').then((mod) => mod.NewHero)
  ),
  'new-steps': dynamic(() => import('./NewSteps').then((mod) => mod.NewSteps)),
  'new-faqs': dynamic(() => import('./NewFAQS').then((mod) => mod.NewFAQS)),
  'cards-carousel': dynamic(() =>
    import('./CardsCarousel').then((mod) => mod.CardsCarousel)
  ),
  'fertility-treatment-scenarios': dynamic(() =>
    import('./FertilityTreatmentScenarios').then(
      (mod) => mod.FertilityTreatmentScenarios
    )
  ),
  'fertility-treatment-costs': dynamic(() =>
    import('./FertilityTreatmentCosts').then(
      (mod) => mod.FertilityTreatmentCosts
    )
  ),
  'new-selected-clinics': dynamic(() =>
    import('./NewSelectedClinics').then((mod) => mod.NewSelectedClinics)
  ),
  'faqs-section': dynamic(() =>
    import('./FAQsSection').then((mod) => mod.FaqsSection)
  ),
}
