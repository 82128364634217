import { NODE_PARAGRAPH, NODE_UL } from 'storyblok-rich-text-react-renderer'

import { Tag } from 'components/ui'
import { Accordion, AccordionItem } from 'components/ui/Accordion'
import { storyblokEditable } from 'lib/storyblok/storyblokEditable'
import {
  RichTextFaqsStoryblok,
  RichTextClinicLocationStoryblok,
  RichTextCtasStoryblok,
} from 'lib/storyblok/types'
import { cn } from 'lib/utils/tailwind'

import { FAQClinicCard } from '../FAQClinicCard'
import { StoryblokCallToAction } from '../StoryblokCallToAction'
import { StoryblokRichText } from './StoryblokRichText'

type Props = {
  block: RichTextFaqsStoryblok
}

export const FAQS_RICH_TEXT_RESOLVERS = {
  node: {
    [NODE_PARAGRAPH]: (children: React.ReactNode) => {
      if (!children) {
        return null
      }
      return (
        <p className="[&_img]:rounded-5 text-twenty [&_img]:mt-5">{children}</p>
      )
    },
    [NODE_UL]: (children: React.ReactNode) => {
      if (!children) {
        return null
      }

      return (
        <ul
          className={cn(
            'my-3 relative',
            '[&_ul]:mt-0 [&_ul]:mb-0 [&_ul]:pl-12',
            "[&>li]:before:content-[''] [&>li]:before:absolute [&>li]:before:size-[0.3em] [&>li]:before:top-[0.75em] [&>li]:before:left-0",
            '[&>li]:before:bg-current [&>li]:before:rounded-full',
            '[&>li]:relative [&>li]:pl-[1em]'
          )}
        >
          {children}
        </ul>
      )
    },
  },
  blok: {
    'rich-text-ctas': (richTextCtas: RichTextCtasStoryblok) => {
      if (!richTextCtas) {
        return null
      }
      const { ctas } = richTextCtas

      return (
        <div className="flex gap-3 mt-5">
          {ctas.map((cta) => (
            <StoryblokCallToAction cta={cta} key={cta._uid} />
          ))}
        </div>
      )
    },
    'rich-text-clinic-location': (
      richTextClinicLocation: RichTextClinicLocationStoryblok
    ) => {
      if (!richTextClinicLocation) {
        return null
      }

      if (typeof richTextClinicLocation.clinic === 'string') return null

      return (
        richTextClinicLocation.clinic.content && (
          <div {...storyblokEditable(richTextClinicLocation)}>
            <FAQClinicCard block={richTextClinicLocation} />
          </div>
        )
      )
    },
  },
}

export const RichTextFaqs = ({ block }: Props) => {
  const { tag, title, faqs } = block

  const typedFaqs = faqs?.map((faq) => ({
    title: faq.question,

    content: (
      <StoryblokRichText
        document={faq.answer ?? { type: 'doc', content: [] }}
        resolvers={FAQS_RICH_TEXT_RESOLVERS}
      />
    ),
    ctas: faq.ctas,
  })) as AccordionItem[]

  return (
    <section className="w-full overflow-hidden text-foreground bg-background">
      <div
        className="mx-auto max-w-rich-text container py-15 px-5 md:px-0"
        {...storyblokEditable(block)}
      >
        {tag && <Tag className="mb-4">{tag}</Tag>}

        {title && (
          <h2 className="text-title-large font-regular mb-8">{title}</h2>
        )}

        {faqs && <Accordion items={typedFaqs} />}
      </div>
    </section>
  )
}
