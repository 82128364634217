import NextImage from 'next/image'
import NextLink from 'next/link'
import { ISbStoryData } from 'storyblok-js-client'
import styled from 'styled-components'

import { useTranslation } from 'lib/i18n'
import {
  BlogPostStoryblok,
  PatientStoryStoryblok,
  ServiceStoryblok,
} from 'lib/storyblok/types'
import {
  getStoryblokImageAttributes,
  sortServicesTypesAlphabetically,
} from 'lib/utils/content'
import { formatDateLocale } from 'lib/utils/datetime'
import { removeTrailingSlash } from 'lib/utils/string'

import { TagList } from '../../../../ui/TagList'
import { getThumbCategories } from './BigThumb'

type Props = {
  item: ISbStoryData<BlogPostStoryblok> | ISbStoryData<PatientStoryStoryblok>

  single_column?: boolean
}

export const ContentThumb = ({ item, ...props }: Props): JSX.Element => {
  const { locale } = useTranslation()

  const categories = Array.from(
    new Set(
      item.content.component === 'patient-story'
        ? getThumbCategories(item.content.categories)
        : getThumbCategories(item.content.services)
    )
  ) as Array<ServiceStoryblok['type']>

  return (
    <NextLink
      href={removeTrailingSlash(`/${item.full_slug}`)}
      {...props}
      className="text-foreground flex flex-col gap-5"
    >
      {item.content.cover_image && item.content.cover_image.filename && (
        <div className="relative h-[252px] md:h-[310px] md:w-full rounded-4 overflow-hidden aspect-auto">
          <NextImage
            src={
              getStoryblokImageAttributes(
                item.content.cover_image,
                '800x400/smart/filters:format(webp)'
              ).src
            }
            alt={getStoryblokImageAttributes(item.content.cover_image).alt}
            fill
            sizes="400"
            style={{ objectFit: 'cover', height: '100%' }}
          />
        </div>
      )}

      <div className="space-y-3">
        <div className="flex items-center gap-3">
          {categories?.length > 0 && (
            <TagList
              hasHiddenTags
              hasColorVariants
              maxVisibleTags={1}
              tags={sortServicesTypesAlphabetically(categories)}
            />
          )}

          <p className="text-sixteen">
            {item.first_published_at
              ? formatDateLocale(item.first_published_at, locale)
              : ''}
          </p>
        </div>

        <h3 className="text-title-small font-regular">{item.content.title}</h3>
      </div>
    </NextLink>
  )
}

export const Thumb = styled(NextLink)`
  color: inherit;
`
