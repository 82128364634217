import { Tag } from 'components/ui'
import { storyblokEditable } from 'lib/storyblok/storyblokEditable'
import { RichTextQuoteStoryblok } from 'lib/storyblok/types'

type Props = {
  block: RichTextQuoteStoryblok
}

export const RichTextQuote = ({ block }: Props) => {
  const { quote, author, tag } = block

  return (
    <div
      className="w-full max-w-[800px] text-foreground mx-auto flex flex-col gap-8 py-10 px-5 md:px-0"
      {...storyblokEditable(block)}
    >
      {quote && (
        <div className="text-title-medium font-regular flex flex-col">
          {'“'}
          <blockquote>{quote}</blockquote>
        </div>
      )}

      <div className="flex flex-col gap-2">
        <p className="text-eighteen font-regular">{author}</p>
        {tag && <Tag className="mb-4">{tag}</Tag>}
      </div>
    </div>
  )
}
