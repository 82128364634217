import { ISbStoryData } from 'storyblok-js-client'

import { Seo } from 'components/Seo'
import { Layout } from 'components/blocks/layout/Layout'
import { DynamicBlock } from 'components/blocks/sections/DynamicBlock'
import { TeamMemberDetailPageStoryblok } from 'lib/storyblok/types'

import { TeamMemberDetailPageHeader } from './TeamMemberDetailPageHeader'

type Props = {
  story: ISbStoryData<TeamMemberDetailPageStoryblok>
}

export const TeamMemberDetailPage = ({ story }: Props): JSX.Element => {
  return (
    <Layout>
      <Seo story={story} />
      {story.content && <TeamMemberDetailPageHeader story={story} />}
      {story.content.body &&
        story.content.body.map((block) => (
          <DynamicBlock key={block._uid} block={block} />
        ))}
    </Layout>
  )
}
