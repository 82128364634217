import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import styled from 'styled-components'

import { CMSLink } from 'components/blocks/deprecated/CMSLink'
import { ClinicLocation } from 'components/blocks/deprecated/ClinicLocator/useFetchClinics'
import { ButtonGroup } from 'components/ui/deprecated/ButtonGroup'
import { LabeledButton } from 'components/ui/deprecated/LabeledButton'
import { Container } from 'components/ui/deprecated/Layout'
import { OldButton } from 'components/ui/deprecated/OldButton'
import { Text } from 'components/ui/deprecated/Text'
import { Theme } from 'components/ui/deprecated/Theme'
import { useDataContext } from 'lib/contexts/dataContext'
import { useOptimalLocation } from 'lib/hooks/useOptimalLocation'
import { addAlphaToColor, dashedLine } from 'lib/style'
import { isArrayNotEmpty } from 'lib/utils/array'
import { toCapitalize } from 'lib/utils/string'

type Props = {
  showClinicModal: () => void
  city: string
  clinics: Array<ClinicLocation | null>
  isLangHome: boolean
  countryCode: string | null
}

export const ClinicNudge = ({
  showClinicModal,
  city,
  clinics,
  isLangHome,
  countryCode,
}: Props): JSX.Element | null => {
  const { config } = useDataContext()
  const [dismiss, setDismiss] = useState(false)
  const [shouldShow, setShouldShow] = useState(false)
  const { push } = useRouter()
  const closeNudge = () => setDismiss(true)

  const hasOnlyOneClinic = clinics.length === 1

  const { notInOptimal, optimalHasRegion } = useOptimalLocation({ countryCode })

  useEffect(() => {
    const isShown =
      !notInOptimal &&
      optimalHasRegion &&
      !dismiss &&
      isLangHome &&
      isArrayNotEmpty(clinics)

    setShouldShow(isShown)
  }, [dismiss, notInOptimal, optimalHasRegion])

  if (shouldShow) {
    return (
      <Theme colorTheme="dark-blue">
        <Wrapper>
          <Holder>
            <Header>
              <Text variant="fourteen" as="p">
                {config?.content.label} {toCapitalize(city)}{' '}
                {config?.content.is_label_question && '?'}
              </Text>
              <SmallCloseButton
                size="micro"
                leftIcon="close"
                variant="ghost"
                onClick={closeNudge}
              />
            </Header>
            <ButtonsHolder>
              <BtnGroup inlineFrom="sm" gap="small">
                <ClinicHolder>
                  {hasOnlyOneClinic ? (
                    <>
                      {config?.content.go_button && clinics && (
                        <LabeledButton
                          buttonLabel={config.content.go_button}
                          label={clinics[0]?.content.name || ''}
                          onClick={() =>
                            push(clinics[0]?.content.website_url || '/')
                          }
                        />
                      )}
                    </>
                  ) : (
                    <OldButton
                      size="micro"
                      variant="text"
                      rightIcon="caret-down"
                      onClick={() => showClinicModal()}
                    >
                      {config?.content.clinic_select}
                    </OldButton>
                  )}
                </ClinicHolder>
                {config?.content.clinics_cta_link && (
                  <CMSLink
                    variant="ghost"
                    size="small"
                    href={config?.content.clinics_cta_link}
                  >
                    {config?.content.clinics_cta_label}
                  </CMSLink>
                )}
              </BtnGroup>
              <CloseButton
                size="small"
                leftIcon="close"
                variant="ghost"
                onClick={closeNudge}
              />
            </ButtonsHolder>
          </Holder>
        </Wrapper>
      </Theme>
    )
  }

  return null
}

const Wrapper = styled.aside`
  background-color: ${({ theme }) => theme.colors.background.default};
  ${({ theme }) =>
    dashedLine('bottom', addAlphaToColor(theme.colors.foreground.default, 20))};

  padding: 0.5rem 0;
`

const Holder = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  flex-direction: column;

  ${({ theme }) => theme.media.md} {
    flex-direction: row;
  }
`

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 0.5rem;

  ${({ theme }) => theme.media.md} {
    margin-bottom: 0;
  }
`

const ButtonsHolder = styled.div`
  display: flex;
  align-items: stretch;
  width: 100%;

  ${({ theme }) => theme.media.md} {
    width: auto;
    align-items: center;
    margin: 0 0 0 2rem;
  }
`

const BtnGroup = styled(ButtonGroup)`
  width: 100%;

  ${({ theme }) => theme.media.md} {
    width: auto;
    flex-wrap: nowrap;
    align-items: center;
  }
`

const SmallCloseButton = styled(OldButton)`
  margin-left: 2rem;

  width: 1.5rem;
  height: 1.5rem;

  flex-shrink: 0;

  ${({ theme }) => theme.media.md} {
    display: none;
  }
`

const CloseButton = styled(OldButton)`
  display: none;

  ${({ theme }) => theme.media.md} {
    display: inline-flex;
    margin-left: 2rem;
    width: 2.5rem;
    height: 2.5rem;
  }

  ${({ theme }) => theme.media.lg} {
    margin-left: 4rem;
  }
`

const Info = styled.div`
  min-height: 2rem;
  white-space: nowrap;

  display: flex;
  align-items: center;
`

const ClinicHolder = styled(Info)`
  ${({ theme }) => theme.media.sm} {
    flex: 1;

    &:not(:first-of-type) {
      ${({ theme }) =>
        dashedLine(
          'left',
          addAlphaToColor(theme.colors.foreground.default, 20)
        )};
      padding-left: 1rem;
      margin-left: 1rem;
    }
  }
`
