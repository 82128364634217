import { cva, VariantProps } from 'class-variance-authority'
import React, {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  ElementType,
  forwardRef,
} from 'react'

import { cn } from 'lib/utils/tailwind'

import { Icon, IconOptions } from './Icon'
import { AnimatedCalendar } from './icons/AnimatedCalendar'
import { AnimatedSupport } from './icons/AnimatedSupport'

type ButtonOrAnchorProps = ButtonHTMLAttributes<HTMLButtonElement> &
  AnchorHTMLAttributes<HTMLAnchorElement>

export interface ButtonProps
  extends ButtonOrAnchorProps,
    VariantProps<typeof buttonVariants> {
  as?: ElementType
  loading?: boolean
  icon?: IconOptions
  alternativeText?: string
}

const buttonVariants = cva(
  [
    'group relative whitespace-nowrap overflow-hidden cursor-pointer flex-shrink-0',
    'flex items-center justify-center gap-2',
    'w-fit',
    'px-5 py-3.5',
    'text-sixteen font-regular',
    'transition duration-200 ease-appearance will-change-transform',
    'rounded-3 outline-none',
    'disabled:pointer-events-none disabled:cursor-not-allowed disabled:opacity-40',
  ].join(' '),
  {
    variants: {
      variant: {
        primary: `
          text-[#F8F5F1]
          bg-accent-secondary
          
          hover:bg-accent-secondary/60
          active:bg-accent-dark

          focus-visible:ring-3 
          focus-visible:ring-accent-secondary/20
        `,
        secondary: `
          text-foreground
          bg-background-subtle

          shadow-button

          hover:bg-foreground/10
          active:bg-foreground/20

          focus-visible:ring-3
          focus-visible:ring-foreground/20
        `,
        tertiary: `
          text-background
          bg-foreground 
          
          hover:bg-foreground/60
          active:bg-foreground-dark

          focus-visible:ring-3 
          focus-visible:ring-foreground/20
        `,
        outline: `
          text-foreground 
          bg-transparent

          border
          border-foreground/20
          
          hover:border-foreground/40
          active:border-foreground/60

          focus-visible:bg-background-sublte
          focus-visible:border-foreground/60
          focus-visible:ring-3 
          focus-visible:ring-foreground/20
        `,
      },
      size: {
        sm: 'h-10 min-w-10',
        md: 'h-12 min-w-12',
      },
      hasIcon: {
        true: '',
        false: '',
      },
      iconPosition: {
        right: 'flex-row',
        left: 'flex-row-reverse',
      },
      square: {
        true: 'p-2.5',
        false: '',
      },
      isFull: {
        true: 'w-full',
      },
      loading: {
        true: 'cursor-progress',
        false: 'hover:enabled:cursor-pointer',
      },
    },
    defaultVariants: {
      variant: 'primary',
      isFull: false,
      size: 'md',
      loading: false,
      iconPosition: 'left',
    },
    compoundVariants: [
      {
        size: 'sm',
        square: true,
        className: 'p-2.5',
      },
      {
        hasIcon: true,
        square: false,
        iconPosition: 'left',
        className: 'ps-4',
      },
      {
        hasIcon: true,
        square: false,
        size: 'sm',
        iconPosition: 'right',
        className: 'pe-3',
      },
      {
        hasIcon: true,
        square: false,
        size: 'md',
        iconPosition: 'right',
        className: 'pe-4',
      },
    ],
  }
)

export const Button = forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  ButtonProps
>(
  (
    {
      children,
      variant = 'primary',
      className,
      icon,
      loading,
      alternativeText,
      isFull,
      size,
      iconPosition,
      as: Component = 'button',
      ...props
    },
    ref
  ) => {
    const shouldShowAlternativeText =
      alternativeText &&
      children &&
      alternativeText.length < children.toString().length

    const hasIcon = !!icon
    const hasOnlyIcon = !children && hasIcon

    return (
      <Component
        ref={ref}
        className={cn(
          buttonVariants({
            hasIcon,
            variant,
            isFull,
            size,
            loading,
            iconPosition,
            square: hasOnlyIcon,
          }),
          className
        )}
        {...props}
      >
        {(children || alternativeText) && (
          <div className="relative flex flex-col">
            {children && (
              <span
                className={cn(
                  alternativeText &&
                    shouldShowAlternativeText &&
                    'transform duration-350 ease-in-out group-hover:-translate-y-12 group-focus-visible:-translate-y-12',
                  loading && 'invisible'
                )}
              >
                {children}
              </span>
            )}

            {shouldShowAlternativeText && (
              <span className="absolute left-0 top-0 translate-y-[48px] duration-350 ease-in-out group-hover:translate-y-0 group-focus-visible:translate-y-0">
                {alternativeText}
              </span>
            )}
          </div>
        )}

        {alternativeText && (icon === 'calendar' || icon === 'support') ? (
          icon === 'calendar' ? (
            <AnimatedCalendar className="opacity-60" />
          ) : (
            <AnimatedSupport className="opacity-60" />
          )
        ) : (
          icon && <Icon icon={icon} size={20} className="opacity-60" />
        )}

        {loading && (
          <div
            role="progressbar"
            aria-label="loading"
            className={cn(
              'absolute left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2'
            )}
          >
            <Icon
              icon="loading"
              size={size === 'sm' ? 16 : 20}
              className="animate-spin opacity-60"
            />
          </div>
        )}
      </Component>
    )
  }
)
