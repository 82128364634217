export type IconProps = {
  size?: number
  className?: string
}

export const AnimatedCalendar = ({ size = 20, className }: IconProps) => {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: size, height: size }}
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.35 3.75H7.5V1.95C7.5 1.5634 7.1866 1.25 6.8 1.25C6.4134 1.25 6.1 1.5634 6.1 1.95V3.75H5C3.61929 3.75 2.5 4.86929 2.5 6.25V15C2.5 16.3807 3.61929 17.5 5 17.5H15C16.3807 17.5 17.5 16.3807 17.5 15V6.25C17.5 4.86929 16.3807 3.75 15 3.75H13.75V1.95C13.75 1.5634 13.4366 1.25 13.05 1.25C12.6634 1.25 12.35 1.5634 12.35 1.95V3.75ZM15 5.15H5C4.39249 5.15 3.9 5.64249 3.9 6.25V15C3.9 15.6075 4.39249 16.1 5 16.1H15C15.6075 16.1 16.1 15.6075 16.1 15V6.25C16.1 5.64249 15.6075 5.15 15 5.15Z"
        fill="currentColor"
      />

      <path
        d="M13.3501 8.14474C12.9386 7.74139 12.3854 7.51547 11.8092 7.51547C11.233 7.51547 10.6798 7.74139
    10.2683 8.14474L10.0041 8.49695L9.69594 8.23279C9.49408 8.0143 9.2487 7.84049 8.97562
    7.72256C8.70253 7.60462 8.40777 7.54517 8.11032 7.54803C7.81287 7.55089 7.51931 7.61599 7.24854
    7.73916C6.97777 7.86232 6.73578 8.04081 6.53816 8.26313C6.34053 8.48546 6.19164 8.74671 6.10108
    9.03005C6.01051 9.31339 5.98027 9.61256 6.01231 9.9083C6.04434 10.204 6.13794 10.4898 6.28708
    10.7472C6.43622 11.0045 6.63759 11.2278 6.87824 11.4027L10.0481 14.4845L13.2181 11.4027C14.2747
    10.3461 14.1866 9.02527 13.3942 8.14474"
        stroke="currentColor"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
        className="transform opacity-0 transition-all duration-350 ease-in-out group-hover:opacity-100 group-focus-visible:opacity-100"
      />

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.35 3.75H7.5V1.95C7.5 1.5634 7.1866 1.25 6.8 1.25C6.4134 1.25 6.1 1.5634 6.1
    1.95V3.75H5C3.61929 3.75 2.5 4.86929 2.5 6.25V15C2.5 16.3807 3.61929 17.5 5 17.5H15C16.3807 17.5
    17.5 16.3807 17.5 15V6.25C17.5 4.86929 16.3807 3.75 15 3.75H13.75V1.95C13.75 1.5634 13.4366 1.25
    13.05 1.25C12.6634 1.25 12.35 1.5634 12.35 1.95V3.75ZM5 5.15H15C15.6075 5.15 16.1 5.64249 16.1
    6.25V15C16.1 15.6075 15.6075 16.1 15 16.1H5C4.39249 16.1 3.9 15.6075 3.9 15V6.25C3.9 5.64249
    4.39249 5.15 5 5.15ZM6.25 8.60005C5.8634 8.60005 5.55 8.91345 5.55 9.30005C5.55 9.68665 5.8634
    10 6.25 10H13.75C14.1366 10 14.45 9.68665 14.45 9.30005C14.45 8.91345 14.1366 8.60005 13.75
    8.60005H6.25Z"
        fill="currentColor"
        className="transform transition-all duration-350 ease-in-out group-focus-within:opacity-0 group-hover:opacity-0"
      />
    </svg>
  )
}
