import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

import { useDataContext } from 'lib/contexts/dataContext'
import { getOptimalDestination } from 'lib/utils/i18n'

export const useOptimalLocation = ({
  countryCode,
}: {
  countryCode: string | null
}) => {
  const { regions } = useDataContext()
  const [navigatorLangs, setNavigatorLangs] = useState<string[]>([])

  const {
    query: { lang },
  } = useRouter()

  useEffect(() => {
    const languages: string[] = navigator.languages.map((l) => l) || []
    setNavigatorLangs(languages)
  }, [])

  const availableTargets = regions?.map((region) => region.code) || []

  const optimalDestination = getOptimalDestination({
    availableTargets,
    countryCode: countryCode || undefined,
    preferredLanguages: navigatorLangs,
  })
  const optimalDestinationLabel = regions?.find(
    (region) => region.code === optimalDestination
  )?.name

  const notInOptimal = optimalDestination !== lang
  const optimalHasRegion = !!optimalDestination.split('-')[1]

  return {
    optimalDestination,
    optimalDestinationLabel,
    notInOptimal,
    optimalHasRegion,
  }
}
