import { cn } from 'lib/utils/tailwind'

export const icons = {
  'arrow-left': require('./icons/arrow-left.svg'),
  'arrow-right': require('./icons/arrow-right.svg'),
  'book-appointment': require('./icons/book-appointment.svg'),
  'box-selected': require('./icons/box-selected.svg'),
  'caret-down': require('./icons/caret-down.svg'),
  'caret-up': require('./icons/caret-up.svg'),
  'chevron-left': require('./icons/chevron-left.svg'),
  'chevron-right': require('./icons/chevron-right.svg'),
  'closure-covid': require('./icons/closure-covid.svg'),
  'double-arrow-left': require('./icons/double-arrow-left.svg'),
  'double-arrow-right': require('./icons/double-arrow-right.svg'),
  'hours-holidays': require('./icons/hours-holidays.svg'),
  'learn-more': require('./icons/learn-more.svg'),
  'map-pointer': require('./icons/map-pointer.svg'),
  'message-bubble': require('./icons/message-bubble.svg'),
  'x-circle': require('./icons/x-circle.svg'),
  support: require('./icons/support.svg'),
  apple: require('./icons/apple.svg'),
  booking: require('./icons/booking.svg'),
  box: require('./icons/box.svg'),
  calendar: require('./icons/calendar.svg'),
  checkmark: require('./icons/checkmark.svg'),
  clinics: require('./icons/clinics.svg'),
  clock: require('./icons/clock.svg'),
  close: require('./icons/close.svg'),
  cookies: require('./icons/cookies.svg'),
  directions: require('./icons/directions.svg'),
  dots: require('./icons/dots.svg'),
  download: require('./icons/download.svg'),
  events: require('./icons/events.svg'),
  expand: require('./icons/expand.svg'),
  external: require('./icons/external.svg'),
  face: require('./icons/face.svg'),
  facebook: require('./icons/facebook.svg'),
  filters: require('./icons/filters.svg'),
  hamburguer: require('./icons/hamburguer.svg'),
  house: require('./icons/house.svg'),
  info: require('./icons/info.svg'),
  less: require('./icons/less.svg'),
  link: require('./icons/link.svg'),
  list: require('./icons/list.svg'),
  loading: require('./icons/loading.svg'),
  location: require('./icons/location.svg'),
  mail: require('./icons/mail.svg'),
  maps: require('./icons/maps.svg'),
  news: require('./icons/news.svg'),
  phone: require('./icons/phone.svg'),
  play: require('./icons/play.svg'),
  plus: require('./icons/plus.svg'),
  search: require('./icons/magnifier.svg'),
  star: require('./icons/star.svg'),
  treatments: require('./icons/treatments.svg'),
  twitter: require('./icons/twitter.svg'),
  user: require('./icons/user.svg'),
  world: require('./icons/world.svg'),
  'in-vitro': require('./icons/in-vitro.svg'),
  baby: require('./icons/baby.svg'),
  lamp: require('./icons/lamp.svg'),
}

export type IconOptions = keyof typeof icons

export function isValidIcon(iconName: unknown): iconName is IconOptions {
  if (typeof iconName !== 'string' || !iconName) return false
  return iconName in icons
}

export const getValidIcon = (icon: unknown): IconOptions | undefined => {
  if (isValidIcon(icon)) {
    return icon
  } else return
}

export type IconProps = {
  icon: IconOptions
  size?: number
  className?: string
}

export const Icon = ({ icon, size = 20, className, ...props }: IconProps) => {
  const IconComponent =
    Object.keys(icons).indexOf(icon) !== -1
      ? icons[icon].ReactComponent || icons[icon].default || null
      : null

  return (
    <div
      className={cn(`inline-flex`, className)}
      style={{ width: size, height: size }}
    >
      <IconComponent {...props} />
    </div>
  )
}
