import NextImage from 'next/image'
import { useRouter } from 'next/router'
import { useState, useEffect } from 'react'

import { Icon, Tag } from 'components/ui'
import { Button } from 'components/ui/Button'
import { useTranslation } from 'lib/i18n'
import { RichTextClinicLocationStoryblok } from 'lib/storyblok/types'
import { getStoryblokImageAttributes, getDistance } from 'lib/utils/content'

import { convertDistance } from '../../lib/utils/convertDistance'

type Props = {
  block: RichTextClinicLocationStoryblok
}

export const FAQClinicCard = ({ block }: Props) => {
  const {
    query: { lang },
  } = useRouter()
  const [, region] = ((lang as string) || '').split('-')
  const [distance, setDistance] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(true)

  const { i18n } = useTranslation()

  const { clinic } = block

  useEffect(() => {
    if ('geolocation' in navigator && typeof clinic !== 'string') {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const coordinates = clinic.content.coordinates.split(',')
          const calculatedDistance = getDistance(
            Number(coordinates[0]),
            Number(coordinates[1]),
            position.coords.latitude,
            position.coords.longitude
          )

          setDistance(convertDistance(calculatedDistance, region))
          setIsLoading(false)
        },
        () => {
          setIsLoading(false)
        }
      )
    } else {
      setIsLoading(false)
    }
  }, [region])

  return typeof clinic !== 'string' ? (
    <div className="flex gap-6 mt-8">
      <div className="rounded-3 relative overflow-hidden w-[170px] h-[200px]">
        <NextImage
          fill
          sizes="200"
          alt={getStoryblokImageAttributes(clinic.content.image)?.alt}
          src={getStoryblokImageAttributes(clinic.content.image)?.src}
          className="pointer-events-none flex-shrink-0 object-cover"
        />
      </div>

      <div className="flex flex-col">
        <Tag icon="location">
          {isLoading ? (
            <Icon
              icon="loading"
              size={16}
              className="animate-spin opacity-60"
            />
          ) : (
            <span className="flex items-center gap-1">
              {clinic.content.country.toString().toUpperCase()}
              {distance && (
                <>
                  <span className="text-foreground/60">•</span>
                  {distance || undefined}
                </>
              )}
            </span>
          )}
        </Tag>

        <p className="text-title-small font-regular mt-3 mb-2">{clinic.name}</p>

        <p className="text-sixteen font-light text-foreground/80">
          {clinic.content.address}
        </p>

        <Button
          as="a"
          target="_blank"
          rel="noopener noreferrer"
          href={`https://www.google.com/maps?q=${clinic.content.coordinates}`}
          variant="outline"
          icon="external"
          iconPosition="right"
          className="mt-auto"
        >
          {i18n('getDirections')}
        </Button>
      </div>
    </div>
  ) : (
    <></>
  )
}
