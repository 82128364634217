import { useMemo } from 'react'

import { AssetStoryblok } from 'lib/storyblok/types'
import { getStoryblokVideoAttributes } from 'lib/utils/content'

type StoryblokVideoProps = React.HTMLAttributes<HTMLVideoElement> & {
  asset: AssetStoryblok
}

export function StoryblokVideo({ asset, ...rest }: StoryblokVideoProps) {
  const { src, format } = useMemo(
    () => getStoryblokVideoAttributes(asset),
    [asset]
  )

  return (
    <video muted playsInline loop autoPlay {...rest}>
      <source src={src} type={`video/${format}`} />
    </video>
  )
}
