import React, { FC, useEffect } from 'react'

import { useBodyLock } from 'lib/hooks/useBodyLock'
import { getYouTubeVideoId } from 'lib/utils/string'

type VideoOverlayProps = {
  videoUrl: string
  setVideoOverlay: (videoUrl: string | null) => void
}

const VideoOverlay: FC<VideoOverlayProps> = ({ videoUrl, setVideoOverlay }) => {
  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setVideoOverlay(null)
      }
    }
    window.addEventListener('keydown', handleEsc)
    return () => window.removeEventListener('keydown', handleEsc)
  }, [setVideoOverlay])

  useBodyLock(true)

  return (
    <div className="fixed inset-0 z-overlay flex items-center justify-center">
      <div
        className="absolute inset-0 bg-accent-subtle/90"
        onClick={() => setVideoOverlay(null)}
      />
      {videoUrl.includes('youtube.com') || videoUrl.includes('youtu.be') ? (
        <iframe
          className="h-full w-full aspect-video rounded-3 md:rounded-8 z-10 max-w-[90vw] max-h-[60vw] sm:max-h-[70vw] md:max-h-[70vw] xl:max-w-[60vw] xl:max-h-[40vw]"
          src={`https://www.youtube.com/embed/${getYouTubeVideoId(videoUrl)}`}
          title="YouTube video player"
          allowFullScreen
          loading="lazy"
          sandbox="allow-scripts allow-same-origin allow-presentation allow-popups allow-popups-to-escape-sandbox allow-forms allow-modals"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; web-share"
        />
      ) : (
        <div className="relative rounded-3 md:rounded-8 z-10 w-full h-auto max-w-[90vw] max-h-[60vw] sm:max-h-[70vw] md:max-h-[70vw] xl:max-w-[70vw] xl:max-h-[70vw] bg-white">
          <video
            controls
            muted
            className="h-full w-full object-contain rounded-3 md:rounded-8"
            autoPlay
            disablePictureInPicture
          >
            <source src={videoUrl} />
          </video>
        </div>
      )}
    </div>
  )
}

export default VideoOverlay
