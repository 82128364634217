import dayjs from 'dayjs'
import { GetStaticPathsResult } from 'next'
import {
  ISbComponentType,
  ISbStoriesParams,
  ISbStoryData,
  ISbStoryParams,
} from 'storyblok-js-client'

import { ServiceFilterType } from 'components/blocks/sections/directories/AllEntries/ContentEntries'
import { CONFIG_FOLDER } from 'lib/constants'
import { Storyblok } from 'lib/storyblok'
import { ServiceStoryblok } from 'lib/storyblok/types'
import { getStoryblokCacheValue } from 'lib/utils/content'
import { removeLeadingAndTrailingSlash } from 'lib/utils/string'

const getNow = () => dayjs().format('YYYY-MM-DD HH:MM')

export const getStory = async (
  slug: string,
  params: ISbStoryParams
): Promise<ISbStoryData | null> => {
  try {
    const res = await Storyblok.getStory(slug, params)

    return res.data.story
  } catch (error) {
    if (
      error &&
      typeof error === 'object' &&
      (('status' in error && error?.status === 404) ||
        ('message' in error && error?.message === 'Not Found'))
    ) {
      // eslint-disable-next-line no-console
      console.info(`Storyblok story '${slug}' not found`)
      return null
    }

    console.error(
      `Unknown error while fetching Storyblok story '${slug}'`,
      error
    )

    throw error
  }
}

type Link = {
  id: number
  slug: string
  name: string
  is_folder: boolean
  parent_id: number
  published: boolean
  position: number
  uuid: string
  is_startpage: boolean
  real_path: string
}

type BlockType = 'allEntriesData' | 'latestEntriesData'

export type EntriesData = {
  // eslint does not like this, but it's required for the storyblok component
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [k: string]: any
  component?: 'latest-entries' | 'all-entries'
}

export const getLinks = async (
  params: Pick<ISbStoriesParams, 'starts_with' | 'version' | 'cv'>
): Promise<Link[]> => {
  try {
    const PER_PAGE = 100
    let all_links: any[] | PromiseLike<Link[]> = []

    for (let page = 1, totalPages = 1; page <= totalPages; page++) {
      const {
        data: { links },
        total,
      } = await Storyblok.get('cdn/links', {
        per_page: PER_PAGE,
        page,
        ...params,
      })

      all_links = all_links.concat(Object.values(links))

      totalPages = Math.max(page, Math.ceil(total / PER_PAGE))
    }

    return all_links
  } catch (error) {
    // FIXME: we should not be catching everything
    console.error('Failed to getLinks', { error, params })
    return []
  }
}

export const getConfigPaths = async (
  links: Link[],
  pagePath: string
): Promise<string[]> => {
  /**
   * get all paths that have a _config folder
   * ['en-at', 'en-at/clinic-name', 'de-at', ...etc]
   */
  const pathsWithConfig = links
    .filter((l) => l.is_folder && l.slug.includes(CONFIG_FOLDER))
    .map((l) => l.slug.replace(`/${CONFIG_FOLDER}`, '/'))

  /**
   * get the _config paths for the current page sorted by more specific first
   * ['en-gb/tfp-boston-place-fertility', 'en-gb']
   */
  const paths = pathsWithConfig
    .filter((path) => `${pagePath}/`.startsWith(path))
    .sort((a, b) => b.length - a.length)

  return paths
}

export const getInConfig = async <T = ISbStoryData>(
  contentType: string,
  // _config paths sorted by "more specific first"
  paths: string[],
  params: ISbStoriesParams
): Promise<T | null> => {
  let result = null

  for (const path of paths) {
    try {
      const {
        data: { stories },
      } = await Storyblok.get(`cdn/stories`, {
        content_type: contentType,
        starts_with: `${path}${CONFIG_FOLDER}`,
        sort_by: 'published_at:desc',
        page: 1,
        per_page: 1,
        resolve_links: 'url',
        ...params,
      })

      if (stories.length > 0) {
        result = stories[0]

        break
      }
    } catch (error) {
      // FIXME: we should not be catching everything
      console.error('Failed to getInConfig', { error, paths, params })
      // noop
    }
  }

  return result
}

export const getRegions = async (): Promise<
  { code: string; name: string }[]
> => {
  // name: de-at
  // value: Österreich (Deutsch)
  const { data } = await Storyblok.get('cdn/datasource_entries', {
    datasource: 'regions',
    cv: getStoryblokCacheValue(),
  })

  const entries: { name: string; value: string }[] = data.datasource_entries

  return entries
    .filter((entry) => /^.+\(.+\)$/gim.test(entry.value.trim()))
    .map((entry) => ({
      code: entry.name,
      name: entry.value,
    }))
}

export const getServices = async (
  isPreview = false
): Promise<Array<ServiceFilterType>> => {
  const {
    data: { stories },
  } = await Storyblok.get(`cdn/stories`, {
    version: isPreview ? 'draft' : 'published',
    content_type: 'service',
    starts_with: `services/`,
    sort_by: 'published_at:desc',
    page: 1,
    per_page: 100,
    resolve_links: 'url',
  })

  const entries: ISbStoryData<ServiceStoryblok>[] = stories

  return entries.map((entry) => ({
    code: entry.name,
    name: entry.slug,
    uuid: entry.uuid,
    type: entry.content.type,
  }))
}

const per_page = 100
const content_type = ['page', 'blog-post', 'patient-story']
export const getPagePaths = async (): Promise<
  GetStaticPathsResult['paths']
> => {
  try {
    const initial = await Storyblok.get('cdn/stories/', {
      version: 'published',
      per_page: 1,
      page: 1,
      filter_query: content_type.join(','),
    })

    const totalPages = Math.ceil(initial.total / per_page)

    const requests = []
    for (let i = 1; i <= totalPages; i++) {
      requests.push(
        Storyblok.get('cdn/stories/', {
          version: 'published',
          per_page: per_page,
          page: i,
          filter_query: content_type.join(','),
        })
      )
    }

    const responses = await Promise.all(requests)

    const paths: GetStaticPathsResult['paths'] = []
    responses.forEach((response) => {
      paths.push(
        ...response.data.stories.map((story: ISbStoryData) => {
          const parts = removeLeadingAndTrailingSlash(story.full_slug).split(
            '/'
          )

          return {
            params: {
              lang: parts[0],
              slug: parts.slice(1).length === 0 ? false : parts.slice(1),
            },
          }
        })
      )
    })

    return paths
  } catch (error) {
    console.error('Failed to getPagePaths', error)
    // FIXME: we should not be catching everything

    return []
  }
}

export const getTranslationStrings = async (
  locale: string
): Promise<Record<string, string>> => {
  const { data } = await Storyblok.get('cdn/datasource_entries', {
    datasource: 'translations',
    dimension: locale,
    page: 1,
    per_page: 1000,
    cv: getStoryblokCacheValue(),
  })

  const entries: {
    name: string
    value: string
    dimension_value: string | null
  }[] = data.datasource_entries

  const messages = entries.reduce<Record<string, string>>((acc, entry) => {
    return {
      ...acc,
      [entry.name]: entry.dimension_value || entry.value,
    }
  }, {})

  return messages
}

const fetchContent = async ({
  contentType,
  page,
  perPage,
  startsWith,
  bySlugs,
  excludeId,
}: {
  contentType: string
  page: number
  perPage: number
  startsWith?: string
  bySlugs?: string
  excludeId?: number
}) => {
  return await Storyblok.get('cdn/stories', {
    content_type: contentType,
    page,
    per_page: perPage ? perPage : per_page,
    sort_by: 'first_published_at:desc',
    filter_query:
      contentType === 'open-evening'
        ? {
            date_time: {
              gt_date: getNow(),
            },
          }
        : {},
    by_slugs: bySlugs,
    starts_with: startsWith,
    excluding_ids: excludeId?.toString(),
  })
}

export const fetchEntriesData = async ({
  story,
  blockType,
  lang,
}: {
  story: ISbStoryData<
    ISbComponentType<string> & {
      // eslint does not like this, but it's required for the storyblok component
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [index: string]: any
    }
  > | null
  blockType: BlockType
  lang?: string
}): Promise<EntriesData> => {
  const data: EntriesData = {
    component:
      blockType === 'allEntriesData' ? 'all-entries' : 'latest-entries',
  }

  const entriesBlocks =
    blockType === 'latestEntriesData'
      ? (story?.content?.body ?? []).filter(
          (block: { component: string }) => block.component === 'latest-entries'
        )
      : (story?.content?.body ?? []).filter(
          (block: { component: string }) => block.component === 'all-entries'
        )

  for await (const block of entriesBlocks) {
    data[block._uid] = await fetchContent({
      contentType: block.content_type,
      page: 1,
      perPage: +block.item_amount,
      startsWith: lang,
      bySlugs: block.by_slugs,
      excludeId: story?.id,
    })

    data['component'] = block.component
  }

  return data
}
