import { HTMLAttributes } from 'react'
import styled from 'styled-components'

import { Text } from 'components/ui/deprecated/Text'
import { useTranslation } from 'lib/i18n'
import { cn } from 'lib/utils/tailwind'

type Props = HTMLAttributes<HTMLDivElement> & {
  isError?: boolean
  fullWidth?: boolean
}

export const Empty = ({ isError, fullWidth, ...props }: Props): JSX.Element => {
  const { i18n } = useTranslation()
  return (
    <Wrapper {...props}>
      <Box className={cn(fullWidth ? 'w-full' : 'w-auto')}>
        <Text as="h3" variant={'title/small/regular'}>
          {i18n(isError ? 'somethingWentWrong' : 'noResults')}
        </Text>
        <Text as="p" variant="sixteen" className="mt-2">
          {i18n(isError ? 'somethingWentWrongDescription' : 'adjustSearch')}
        </Text>
      </Box>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;
  margin: 2rem 0;
`

const Box = styled.div`
  text-align: center;

  border: 1px dashed ${({ theme }) => theme.colors.foreground.subtle};
  border-radius: 0.75rem;
  padding: 4rem 2rem;
`
