import { ReactNode } from 'react'

import { cn } from 'lib/utils/tailwind'

import { Icon, IconOptions } from './Icon'

export type TagProps = {
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  icon?: IconOptions
  className?: string
  children?: ReactNode
  size?: 'sm' | 'default'
  variant?: 'primary' | 'secondary'
}

export const Tag = ({
  icon,
  onMouseEnter,
  onMouseLeave,
  className,
  children,
  size = 'default',
  variant = 'primary',
  ...props
}: TagProps): JSX.Element => {
  return (
    <span
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={cn(
        'w-fit inline-flex items-center justify-center gap-1 rounded-2',
        size === 'sm'
          ? 'py-1 px-2 text-fourteen'
          : 'pt-1 pb-[5px] px-2.5 text-sixteen',
        variant === 'secondary'
          ? 'bg-accent-secondary/5 text-accent-secondary'
          : 'bg-foreground/5 text-foreground',
        'font-regular',
        icon && 'ps-2',
        className
      )}
      {...props}
    >
      {icon && (
        <Icon
          size={16}
          icon={icon}
          className={
            variant === 'secondary'
              ? 'text-accent-secondary/60'
              : 'text-foreground/60'
          }
        />
      )}
      {children}
    </span>
  )
}
