import { AnimatePresence, motion } from 'framer-motion'
import { ReactNode, useCallback } from 'react'
import styled from 'styled-components'

import { Icon } from 'components/ui'
import { OldButton } from 'components/ui/deprecated/OldButton'
import { SearchInput } from 'components/ui/deprecated/SearchInput'
import { useBodyLock } from 'lib/hooks/useBodyLock'
import { addAlphaToColor } from 'lib/style'

import { Loader } from './Loader'

type Props = {
  isVisible: boolean
  onClose: () => void
  value: string
  onChange: React.ChangeEventHandler<HTMLInputElement>
  placeholder?: string
  loading: boolean
  debouncedSearch: string
  children: ReactNode
}

export const MobileSearch = ({
  isVisible,
  onClose,
  value,
  onChange,
  placeholder,
  loading,
  debouncedSearch,
  children,
}: Props) => {
  useBodyLock(isVisible)

  const handleClose = useCallback(() => {
    onClose()
  }, [onClose])

  return (
    <AnimatePresence>
      {isVisible && (
        <Overlay
          onMouseDown={handleClose}
          onTouchStart={handleClose}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0, transition: { delay: 0.1 } }}
          transition={{ ease: 'easeOut', duration: 0.1 }}
        >
          <Holder
            onMouseDown={(e) => e.stopPropagation()}
            onTouchStart={(e) => e.stopPropagation()}
            initial={{ y: '100%' }}
            animate={{ y: 0 }}
            exit={{ y: '100%' }}
            transition={{ type: 'tween', damping: 35, stiffness: 500 }}
          >
            <div>
              <Wrapper>
                <SearchInput
                  placeholder={placeholder}
                  value={value}
                  onChange={onChange}
                  autoFocus
                />
                <OldButton
                  variant="ghost"
                  onClick={handleClose}
                  className="w-12 ml-3.5"
                >
                  <Icon icon="close" />
                </OldButton>
              </Wrapper>
              <InnerWrapper>
                {loading ? <Loader /> : <>{debouncedSearch && children}</>}
              </InnerWrapper>
            </div>
          </Holder>
        </Overlay>
      )}
    </AnimatePresence>
  )
}

const Overlay = styled(motion.div)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${({ theme }) => theme.zIndex.high};
  background-color: rgba(0, 0, 0, 0.3);
`

const Holder = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: default;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.background.default};
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
`

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1.125rem 1.25rem;
  align-items: center;
`

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
  align-items: flex-start;
  max-height: calc(100vh - 8rem);
  overflow-y: auto;
  text-align: left;
  border-top: 1px dashed
    ${({ theme }) => addAlphaToColor(theme.colors.foreground.default, 10)};
`
