export type IconProps = {
  size?: number
  className?: string
}

export const AnimatedSupport = ({ size = 20, className }: IconProps) => {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: size, height: size }}
      className={className}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.0478 7.46892C16.4848 7.05696 17.0674 6.8351 17.6678 6.85207C18.2691 6.86907 18.8391 7.12426 19.2523 7.56149C19.6655 7.99872 19.8881 8.58218 19.8711 9.18352C19.8541 9.78421 19.5994 10.3536 19.1631 10.7667L19.1616 10.768L15.3493 14.4146C14.6377 15.2149 13.6385 15.5986 12.5829 15.5986H9.24954C8.87639 15.5986 8.66691 15.7199 8.56287 15.8586C8.53783 15.892 8.50938 15.9227 8.47797 15.9501L7.62585 16.6957L8.00583 17.0757C8.24015 17.31 8.24015 17.6899 8.00583 17.9243C7.77152 18.1586 7.39162 18.1586 7.1573 17.9243L2.99248 13.7594C2.99179 13.7587 2.99109 13.7581 2.9904 13.7574L2.1573 12.9243C1.92299 12.6899 1.92299 12.31 2.1573 12.0757C2.39162 11.8414 2.77152 11.8414 3.00583 12.0757L3.4232 12.4931L7.63473 8.43197C8.03182 7.93475 8.63893 7.73398 9.24889 7.73398H11.7489C12.35 7.73398 12.9266 7.97279 13.3517 8.39787C13.6382 8.68439 13.8401 9.03971 13.9412 9.42501L16.0478 7.46892ZM4.27186 13.3418L6.77544 15.8453L7.64552 15.084C8.04275 14.596 8.64475 14.3986 9.24954 14.3986H12.5829C13.3514 14.3986 14.0107 14.1223 14.4647 13.6035C14.4764 13.5901 14.4886 13.5773 14.5015 13.565L18.3348 9.89834L18.3374 9.89584C18.5433 9.70125 18.6635 9.43282 18.6715 9.14961C18.6795 8.86641 18.5747 8.59162 18.3801 8.3857C18.1855 8.17978 17.9171 8.0596 17.6339 8.05159C17.3507 8.04359 17.0759 8.14841 16.87 8.34301L16.8662 8.34662L16.8661 8.3466L13.3661 11.5966C13.3499 11.6117 13.333 11.6257 13.3156 11.6387C12.8943 12.0417 12.3332 12.2673 11.7489 12.2673H10.0822C9.75085 12.2673 9.48222 11.9987 9.48222 11.6673C9.48222 11.3359 9.75085 11.0673 10.0822 11.0673H11.7489C12.0318 11.0673 12.3031 10.9549 12.5031 10.7549C12.7032 10.5549 12.8156 10.2835 12.8156 10.0006C12.8156 9.71775 12.7032 9.44644 12.5031 9.2464C12.3031 9.04636 12.0318 8.93398 11.7489 8.93398H9.24889C8.87574 8.93398 8.66626 9.05526 8.56222 9.19398C8.543 9.2196 8.52176 9.24365 8.4987 9.26589L4.27186 13.3418Z"
        fill="currentColor"
      />

      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.1857 1.84842C15.7998 1.6422 16.4654 1.64937 17.0749 1.86877C17.6843 2.08817 18.2018 2.50693 18.5435 3.0572C18.8792 3.59789 19.0259 4.2344 18.9611 4.86689C18.9447 6.04499 18.0961 6.94612 17.5356 7.50662L14.6189 10.4233C14.387 10.6552 14.0118 10.6579 13.7766 10.4294L10.8599 7.59605C10.8483 7.58475 10.8371 7.57299 10.8264 7.56079C10.7796 7.50732 10.7302 7.45194 10.679 7.39453C10.1523 6.80447 9.43386 5.99965 9.42802 4.84651C9.40189 4.22825 9.57308 3.61757 9.91703 3.10286C10.2636 2.58425 10.7673 2.1904 11.3542 1.97912C11.941 1.76785 12.5802 1.75028 13.1778 1.92899C13.5397 2.03723 13.8748 2.21402 14.1661 2.44743C14.4589 2.18102 14.8055 1.97612 15.1857 1.84842ZM16.6684 2.99784C16.3133 2.87001 15.9255 2.86583 15.5678 2.98598C15.21 3.10612 14.9034 3.34354 14.6974 3.65977C14.5906 3.82385 14.4104 3.92548 14.2147 3.93202C14.019 3.93856 13.8324 3.8492 13.7148 3.69262C13.4944 3.39904 13.1857 3.18387 12.8339 3.07868C12.4822 2.9735 12.106 2.98384 11.7606 3.10819C11.4152 3.23254 11.1187 3.46435 10.9148 3.76959C10.7108 4.07483 10.61 4.43742 10.6273 4.80413C10.6278 4.81353 10.628 4.82294 10.628 4.83235C10.628 5.51641 11.0394 5.9848 11.619 6.64456C11.65 6.67988 11.6815 6.71574 11.7134 6.75222L14.1885 9.15659L16.687 6.65809C17.2878 6.05738 17.7613 5.47065 17.7613 4.83235C17.7613 4.80957 17.7626 4.7868 17.7652 4.76417C17.8081 4.38924 17.7231 4.01082 17.524 3.69023C17.325 3.36963 17.0235 3.12566 16.6684 2.99784Z"
        fill="currentColor"
        className="transition-all transform duration-350 ease-in-out origin-[80%_100%] group-hover:scale-[109%] group-focus-within:scale-[109%]"
      />
    </svg>
  )
}
