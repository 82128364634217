import Image, { ImageProps } from 'next/image'
import { useMemo } from 'react'

import { AssetStoryblok } from 'lib/storyblok/types'
import { getStoryblokImageAttributes } from 'lib/utils/content'

type StoryblokImageProps = Omit<ImageProps, 'src' | 'alt'> & {
  asset: AssetStoryblok
}

export const StoryblokImage = ({ asset, ...rest }: StoryblokImageProps) => {
  const attrs = useMemo(() => getStoryblokImageAttributes(asset), [asset])

  if (!attrs.src) {
    return null
  }

  return <Image {...attrs} {...rest} alt={attrs.alt || ''} />
}
