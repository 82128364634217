import { ImgHTMLAttributes } from 'react'

import { AssetStoryblok } from 'lib/storyblok/types'
import { getStoryblokImageAttributes } from 'lib/utils/content'

type ObjectPosition = 'top' | 'bottom' | 'center' | 'left' | 'right'

type Props = ImgHTMLAttributes<HTMLImageElement> & {
  image: AssetStoryblok
  transformOption?: string
  objectposition?: ObjectPosition
}

export const CMSImage = ({
  image,
  transformOption,
  ...props
}: Props): JSX.Element => {
  const { src, alt } = getStoryblokImageAttributes(image, transformOption)
  return <img src={src} alt={alt} {...props} />
}
