import React from 'react'
import styled from 'styled-components'

import { Container } from 'components/ui/deprecated/Layout'

const redChristmasFlare = '/assets/flares/xmasFlare-red.png'
const redChristmasResponsiveFlare = '/assets/flares/xmasFlareResponsive_red.png'
const yellowChristmasFlare = '/assets/flares/xmasFlare-yellow.png'
const yellowChristmasResponsiveFlare =
  '/assets/flares/xmasFlareResponsive_yellow.png'

interface SingleFlareProps {
  isMobile?: boolean
}

interface FlareProps extends SingleFlareProps {
  flare?: string
}

const ChristmasFlare = ({ isMobile }: SingleFlareProps) => {
  return (
    <FlareWrapper>
      <FlareImage
        index={1}
        src={isMobile ? redChristmasResponsiveFlare : redChristmasFlare}
        alt={'Christmas Flare'}
      />
      <FlareImage
        index={2}
        src={isMobile ? yellowChristmasResponsiveFlare : yellowChristmasFlare}
        alt={'Christmas Flare'}
      />
    </FlareWrapper>
  )
}

const Flare: React.FC<FlareProps> = ({ flare, isMobile }) => {
  switch (flare) {
    case 'christmas':
      return <ChristmasFlare isMobile={isMobile} />
    default:
      return null
  }
}

const FlareWrapper = styled(Container)`
  width: 100%;
  transform: translateY(-2px);
  pointer-events: none;
`

const FlareImage = styled.img`
  @keyframes multiple-image-crossfade {
    0% {
      visibility: visible;
    }

    49% {
      visibility: visible;
    }

    50% {
      visibility: hidden;
    }

    100% {
      visibility: hidden;
    }
  }

  visibility: visible;

  &:not(:nth-child(1)) {
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 1rem;
    width: calc(100% - 2rem);
  }

  animation: multiple-image-crossfade 2s linear infinite;
  animation-delay: ${({ index }: { index: number }) =>
    Number(index || 0) + 1 * 2}s;
  width: '100%';
`

export { Flare }
