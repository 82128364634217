import { useRef, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

import { MODAL_ROOT_ID } from 'lib/constants'

export const Portal: React.FC<{
  selector?: string
  children: React.ReactNode
}> = ({ children, selector = `#${MODAL_ROOT_ID}` }) => {
  const ref = useRef<null | HTMLElement>(null)
  const [mounted, setMounted] = useState<boolean>(false)

  useEffect(() => {
    ref.current = document.querySelector(selector)
    setMounted(true)
  }, [selector])

  return mounted && ref.current ? createPortal(children, ref.current) : null
}
