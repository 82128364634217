import NextImage from 'next/image'
import React from 'react'
import styled from 'styled-components'

import { Carousel as CarouselComponent } from 'components/ui/deprecated/Carousel'
import { NewMediaCarouselStoryblok } from 'lib/storyblok/types'
import { getStoryblokImageAttributes } from 'lib/utils/content'

type Props = {
  block: NewMediaCarouselStoryblok
}

export const NewMediaCarousel: React.FC<Props> = ({ block, ...props }) => {
  const { media } = block
  return (
    <>
      {media && (
        <StyledCarousel itemsPerView={3} {...props}>
          {media.map((mediaContent) => (
            <StyledMedia
              key={mediaContent.id}
              src={getStoryblokImageAttributes(mediaContent).src}
              width={getStoryblokImageAttributes(mediaContent).width}
              height={getStoryblokImageAttributes(mediaContent).height}
              alt={getStoryblokImageAttributes(mediaContent).alt || ''}
            />
          ))}
        </StyledCarousel>
      )}
    </>
  )
}

const StyledCarousel = styled(CarouselComponent)`
  padding: 2rem 0;

  ${({ theme }) => theme.media.md} {
    padding: 2.5rem 0;
  }
`

const StyledMedia = styled(NextImage)`
  border-radius: 1rem;

  object-fit: contain;
  height: fit-content;
  width: 100%;
`
