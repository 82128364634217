import { ISbStoryData } from 'storyblok-js-client'

import { Components } from '.'
import { Editable } from '../deprecated/Editable'

const Placeholder = ({ componentName }: { componentName: string }) => {
  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line no-console
    console.log(`Uncaught dynamic block: `, componentName)
  }

  return null
}

export const DynamicBlock: React.FC<{ block: ISbStoryData['content'] }> = ({
  block,
}) => {
  if (!block.component) return null

  if (typeof Components[block.component] !== 'undefined') {
    const Component = Components[block.component]
    return (
      <Editable block={block}>
        <Component block={block} />
      </Editable>
    )
  }

  return <Placeholder componentName={block.component} />
}
