import { ElementType, ReactNode } from 'react'
import styled, { css } from 'styled-components'

import { getColorFromObject } from 'lib/style'

const variants = [
  'twelve',
  'twelve/caps',
  'fourteen',
  'fourteen/underline',
  'fourteen/caps',
  'sixteen',
  'sixteen/regular',
  'sixteen/underline',
  'eighteen',
  'eighteen/regular',
  'twenty',
  'twenty/regular',
  'title',
  'title/small',
  'title/small/regular',
  'title/medium',
  'title/large',
  'title/card',
  'display',
] as const

type Variants = typeof variants[number]

const sans = css`
  // font already in body
  font-family: inherit;
`

const getFontConfig = (
  sizeInPx: number,
  lineHeightInPx: number,
  weight: number
) => css`
  font-size: ${({ theme }) => sizeInPx / theme.typography.bodyFontSize + 'rem'};
  line-height: ${lineHeightInPx / sizeInPx};
  font-weight: ${weight};
`

// twelve
const twelve = css`
  ${sans};
  ${getFontConfig(12, 12, 300)}
`

const twelveCaps = css`
  ${twelve}

  text-transform: uppercase;
`

// fourteen
const fourteen = css`
  ${sans};
  ${getFontConfig(14, 18, 300)}
`

const fourteenUnderline = css`
  ${fourteen}

  text-decoration: underline;
`

const fourteenCaps = css`
  ${sans};
  ${getFontConfig(14, 18, 300)}

  text-transform: uppercase;
`

// sixteen
const sixteen = css`
  ${sans};
  ${getFontConfig(16, 20, 300)}
`

const sixteenRegular = css`
  ${sans};
  ${getFontConfig(16, 20, 400)}
`

const sixteenUnderline = css`
  ${sixteenRegular}

  text-decoration: underline;
`

// eighteen
const eighteen = css`
  ${sans};
  ${getFontConfig(18, 24, 300)}
`

const eighteenRegular = css`
  ${sans};
  ${getFontConfig(18, 24, 400)}
`

// twenty
const twenty = css`
  ${sans};
  ${getFontConfig(18, 24, 300)}

  ${({ theme }) => theme.media.md} {
    ${getFontConfig(20, 28, 300)}
  }
`

const twentyRegular = css`
  ${sans};
  ${getFontConfig(20, 28, 400)}
`

// title
const title = css`
  ${sans};
  letter-spacing: -0.02em;
  ${getFontConfig(32, 34, 400)}

  ${({ theme }) => theme.media.md} {
    ${getFontConfig(48, 50, 400)}
  }

  ${({ theme }) => theme.media.lg} {
    ${getFontConfig(56, 58, 400)}
  }
`

const titleSmall = css`
  ${sans};
  ${getFontConfig(24, 26, 300)}
`

const titleSmallRegular = css`
  ${sans};
  ${getFontConfig(24, 26, 400)}
`

const titleMedium = css`
  ${sans};
  ${getFontConfig(24, 32, 400)}

  ${({ theme }) => theme.media.md} {
    ${getFontConfig(32, 34, 400)}
  }

  letter-spacing: -0.02em;
`

const titleLarge = css`
  ${sans};
  letter-spacing: -0.02em;

  ${getFontConfig(32, 34, 400)}

  ${({ theme }) => theme.media.md} {
    ${getFontConfig(44, 46, 400)}
  }
`

const titleCard = css`
  ${sans};
  letter-spacing: -0.02em;

  ${getFontConfig(32, 34, 400)}

  ${({ theme }) => theme.media.md} {
    ${getFontConfig(48, 50, 400)}
  }
`

// display
const display = css`
  ${sans};
  letter-spacing: -0.02em;
  ${getFontConfig(48, 50, 400)}

  ${({ theme }) => theme.media.md} {
    ${getFontConfig(56, 58, 400)}
  }

  ${({ theme }) => theme.media.lg} {
    ${getFontConfig(72, 74, 400)}
  }
`

// react-docgen-typescript can't handle this recursive generic type
// and we're getting "Maximum call stack size exceeded"
// TODO: change `color?: string` to `color?: ColorTypes`
//
// // https://stackoverflow.com/questions/58434389/typescript-deep-keyof-of-a-nested-object
// // eslint-disable-next-line prettier/prettier
// type Join<K, P> = K extends string | number ? P extends string | number ? `${K}${'' extends P ? '' : '.'}${P}` : never : never;
// type Iterate<T> = T extends Record<string, unknown> ? { [K in keyof T]-?: Join<K, Iterate<T[K]>> }[keyof T] : ''

// type ColorTypes = Iterate<typeof lightTheme>

export type TextProps = {
  variant?: Variants
  color?: string
  as: ElementType
  children: ReactNode
}

export const Text = styled.p<TextProps>`
  ${({ variant }) => {
    const variants = {
      twelve: twelve,
      'twelve/caps': twelveCaps,

      fourteen: fourteen,
      'fourteen/underline': fourteenUnderline,
      'fourteen/caps': fourteenCaps,

      sixteen: sixteen,
      'sixteen/regular': sixteenRegular,
      'sixteen/underline': sixteenUnderline,

      eighteen: eighteen,
      'eighteen/regular': eighteenRegular,

      twenty: twenty,
      'twenty/regular': twentyRegular,

      title: title,

      'title/small': titleSmall,
      'title/small/regular': titleSmallRegular,

      'title/medium': titleMedium,

      'title/large': titleLarge,

      'title/card': titleCard,

      display: display,
    }

    return variants[variant || 'sixteen']
  }}

  ${({ theme, color }) => {
    const defaultColor = 'inherit'
    const themeColor = color
      ? getColorFromObject(theme.colors, color)
      : defaultColor

    return css`
      color: ${themeColor || defaultColor};
    `
  }}
`
Text.defaultProps = {
  variant: 'sixteen',
}
