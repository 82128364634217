import { cn } from 'lib/utils/tailwind'

interface SkeletonProps {
  className?: string
}

export function Skeleton({ className }: SkeletonProps) {
  return (
    <div
      className={cn(
        'bg-gradient-to-r from-foreground/5 to-foreground/[0.01] animate-pulse',
        className
      )}
    />
  )
}
