import NextLink from 'next/link'
import { ISbStoryData } from 'storyblok-js-client'

import { Tag } from 'components/ui'
import { storyblokEditable } from 'lib/storyblok/storyblokEditable'
import { ServiceDetailPageStoryblok } from 'lib/storyblok/types'
import { removeTrailingSlash } from 'lib/utils/string'
import { cn } from 'lib/utils/tailwind'

type Props = {
  item: ISbStoryData<ServiceDetailPageStoryblok>
}

export const ServiceThumb = ({ item, ...props }: Props): JSX.Element => {
  const serviceColors = (type: ServiceDetailPageStoryblok['type']) => {
    switch (type) {
      case 'diagnosis':
        return {
          bg: 'bg-background-secondary-diagnosis-light',
          tag: 'bg-background-secondary-diagnosis',
        }
      case 'lgbtqia+':
        return {
          bg: 'bg-background-secondary-lgbtqia-light',
          tag: 'bg-background-secondary-lgbtqia',
        }
      case 'treatment':
        return {
          bg: 'bg-background-secondary-treatments-light',
          tag: 'bg-background-secondary-treatments',
        }
      case 'donation':
        return {
          bg: 'bg-background-secondary-donation-light',
          tag: 'bg-background-secondary-donation',
        }
      case 'preservation':
        return {
          bg: 'bg-background-secondary-preservation-light',
          tag: 'bg-background-secondary-preservation',
        }
      case 'advice':
        return {
          bg: 'bg-background-secondary-advice-light',
          tag: 'bg-background-secondary-advice',
        }
      case 'news':
        return {
          bg: 'bg-background-secondary-news-light',
          tag: 'bg-background-secondary-news',
        }

      default:
        return {
          bg: 'bg-background-secondary-news-light',
          tag: 'bg-background-secondary-news',
        }
    }
  }

  const { full_slug, content } = item
  const { title, description, type } = item.content

  return (
    <NextLink
      href={removeTrailingSlash(`/${full_slug}`)}
      className={cn(
        'flex flex-col justify-between h-full rounded-4 p-5 overflow-hidden text-foreground',
        serviceColors(type).bg
      )}
      {...props}
      {...storyblokEditable(content)}
    >
      <p className="text-twenty font-regular">{title}</p>
      <p className="text-eighteen">{description}</p>

      {type && (
        <Tag
          className={cn(
            'mt-3',
            type === 'lgbtqia+' ? 'uppercase' : 'capitalize',
            serviceColors(type).tag
          )}
        >
          {type}
        </Tag>
      )}
    </NextLink>
  )
}
