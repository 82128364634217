import styled, { css } from 'styled-components'

import { addAlphaToColor, focusOutline } from '../../../lib/style'
import { Text } from './Text'

export const StyledLabel = styled(Text)`
  width: 100%;

  display: block;
  padding: 0.75rem 0;
`

export const FieldStyles = css<{ error?: boolean }>`
  display: block;

  width: 100%;

  background: none;
  color: ${({ theme }) => theme.colors.foreground.default};
  font-family: inherit;
  font-size: 1rem;

  border: 1px solid
    ${({ theme }) => addAlphaToColor(theme.colors.foreground.default, 20)};
  ${({ theme, error }) =>
    error &&
    css`
      border-color: ${theme.colors.accent.subtle};
    `}
  border-radius: .75rem;
  outline: none;

  padding: 0.875rem 1rem;

  &:disabled {
    color: ${({ theme }) =>
      addAlphaToColor(theme.colors.foreground.default, 60)};
    background-color: ${({ theme }) =>
      addAlphaToColor(theme.colors.foreground.default, 5)};
  }

  @media (hover: hover) {
    &:hover:not(:focus) {
      border: 1px solid ${({ theme }) => theme.colors.foreground.default};
    }
  }

  ${({ theme }) =>
    css`
      ${focusOutline(theme.colors.foreground.default)}
    `}

  ::placeholder {
    color: ${({ theme }) =>
      addAlphaToColor(theme.colors.foreground.default, 60)}};
  }
`

export const ErrorText = styled(Text)`
  display: block;
  color: ${({ theme }) => theme.colors.accent.subtle};
  margin-top: 0.25rem;
`
