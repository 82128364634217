import { storyblokEditable } from 'lib/storyblok/storyblokEditable'
import { RichTextHighlightCardStoryblok } from 'lib/storyblok/types'

import { StoryblokCallToAction } from '../StoryblokCallToAction'

type Props = {
  block: RichTextHighlightCardStoryblok
}

export const RichTextHighlightCard = ({ block }: Props) => {
  const { title, description, ctas } = block

  return (
    <div className="w-full text-foreground py-10 px-5 md:px-0">
      <div
        className="flex flex-col gap-4 mx-auto max-w-rich-text p-8 bg-foreground/5 rounded-4 space-y-6"
        {...storyblokEditable(block)}
      >
        <div className="flex flex-col space-y-2.5">
          <p className="text-title-medium font-regular">{title}</p>
          <p className="text-twenty/normal lg:max-w-[450px]">{description}</p>
        </div>

        <div className="flex gap-3">
          {ctas.map((cta) => (
            <StoryblokCallToAction cta={cta} key={cta._uid} />
          ))}
        </div>
      </div>
    </div>
  )
}
