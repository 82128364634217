import { ISbStoryData } from 'storyblok-js-client'

import { Seo } from 'components/Seo'
import { Layout } from 'components/blocks/layout/Layout'
import { DynamicBlock } from 'components/blocks/sections/DynamicBlock'
import { SatelliteClinicPageStoryblok } from 'lib/storyblok/types'

import { SatelliteClinicPageHeader } from './SatelliteClinicPageHeader'

type Props = {
  story: ISbStoryData<SatelliteClinicPageStoryblok>
}

export const SatelliteClinicPage = ({ story }: Props): JSX.Element => {
  return (
    <Layout>
      <Seo story={story} />
      {story.content && <SatelliteClinicPageHeader story={story} />}
      {story.content.body &&
        story.content.body.map((block) => (
          <DynamicBlock key={block._uid} block={block} />
        ))}
    </Layout>
  )
}
