import { ButtonHTMLAttributes, forwardRef, Ref } from 'react'
import styled, { css, keyframes } from 'styled-components'

import { cn } from 'lib/utils/tailwind'

import { addAlphaToColor, focusOutline } from '../../../lib/style'
import { Icon, IconOptions } from '../Icon'
import { IconHolder } from './IconHolder'
import { Text } from './Text'

export type ButtonVariant = 'solid' | 'accent' | 'ghost' | 'text' | 'secondary'
export type ButtonSize = 'default' | 'small' | 'micro'

export type ButtonBaseProps = {
  variant?: ButtonVariant
  size?: ButtonSize
  isFull?: boolean
  isLoading?: boolean
  leftIcon?: IconOptions
  rightIcon?: IconOptions
  badge?: number | string
}
export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
  ButtonBaseProps

export const OldButton = forwardRef(function OldButton(
  {
    variant = 'solid',
    size = 'default',
    isFull = false,
    isLoading = false,
    leftIcon,
    rightIcon,
    children,
    badge,
    ...props
  }: ButtonProps,
  ref: Ref<HTMLButtonElement>
) {
  return (
    <StyledButton
      isFull={isFull}
      size={size}
      variant={variant}
      ref={ref}
      {...props}
      className={cn(
        leftIcon && size === 'small' && children
          ? 'px-3'
          : leftIcon && size === 'default'
          ? 'px-4'
          : 'px-5'
      )}
    >
      {isLoading && <ButtonLoader variant={variant} />}

      <div
        className={cn(
          'flex items-center justify-center gap-2',
          isLoading ? 'opacity-0' : 'opacity-100'
        )}
      >
        {leftIcon && (
          <IconHolder>
            <Icon icon={leftIcon} size={20} />
          </IconHolder>
        )}

        {children}

        {rightIcon && (
          <IconHolder>
            <Icon icon={rightIcon} size={20} />
          </IconHolder>
        )}

        {badge && (
          <Badge>
            <Text as="p" variant="fourteen">
              {badge}
            </Text>
          </Badge>
        )}
      </div>
    </StyledButton>
  )
})

type BProps = Required<Pick<ButtonProps, 'isFull' | 'size' | 'variant'>>
export const StyledButton = styled.button<BProps>`
  position: relative;
  box-sizing: border-box;

  appearance: none;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  transition: opacity ${({ theme }) => theme.transitions.ease('150ms')},
    box-shadow ${({ theme }) => theme.transitions.ease('250ms')};

  user-select: none;

  width: ${({ isFull }) => (isFull ? '100%' : 'auto')};

  ${({ isFull }) =>
    isFull &&
    css`
      flex: 1;
    `}

  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;

  white-space: nowrap;
  vertical-align: middle;

  outline: none;

  &:disabled {
    opacity: 0.75;
    pointer-events: none;
  }

  ${({ size }) => {
    const sizes = {
      default: css`
        height: 3rem;
        border-radius: 0.75rem;
        padding: 0.875rem 1.25rem;

        font-size: 1rem;
      `,
      small: css`
        height: 2.5rem;
        border-radius: 0.75rem;
        padding: 0.625rem 1rem;

        font-size: 1rem;
      `,
      micro: css`
        height: 1.5rem;
        border-radius: 0.75rem;
        padding: 0.075rem 0.25rem;

        font-size: 0.875rem;
      `,
    }

    return sizes[size]
  }}

  ${({ theme, variant }) => {
    const variants = {
      solid: css`
        background-color: ${theme.colors.foreground.default};
        color: ${theme.colors.background.default};
        border: 1px solid transparent;

        ${focusOutline(theme.colors.foreground.default)}

        @media (hover: hover) {
          &:hover {
            opacity: 0.8;
          }
        }

        &:active {
          opacity: 1;
          background-color: ${theme.colors.foreground.dark};
        }
      `,
      secondary: css`
        background-color: ${theme.colors.background.subtle};
        color: ${theme.colors.foreground.default};
        border: 1px solid transparent;

        ${focusOutline(theme.colors.foreground.default)}

        @media (hover: hover) {
          &:hover {
            background-color: ${addAlphaToColor(
              theme.colors.foreground.default,
              20
            )};
          }
        }

        &:active {
          color: ${theme.colors.accent.subtle};
        }
      `,
      accent: css`
        background-color: ${theme.colors.accent.secondary};
        color: ${theme.colors.accent.default};
        border: 1px solid transparent;
        box-shadow: ${({ theme }) => theme.shadows.small};

        ${focusOutline(theme.colors.foreground.default)}

        @media (hover: hover) {
          &:hover {
            background-color: ${addAlphaToColor(
              theme.colors.accent.secondary,
              80
            )};
          }
        }

        &:active {
          background-color: ${theme.colors.accent.dark};
        }
      `,
      ghost: css`
        background-color: transparent;
        color: ${theme.colors.foreground.default};
        border: 1px solid
          ${addAlphaToColor(theme.colors.foreground.default, 20)};

        ${focusOutline(theme.colors.foreground.default)}

        @media (hover: hover) {
          &:hover {
            border: 1px solid
              ${addAlphaToColor(theme.colors.foreground.default, 40)};
          }
        }

        &:active {
          border: 1px solid
            ${addAlphaToColor(theme.colors.foreground.default, 60)};
        }
      `,
      text: css`
        background-color: transparent;
        color: ${theme.colors.foreground.default};
        border: 1px solid transparent;

        ${focusOutline(theme.colors.foreground.default)}

        @media (hover: hover) {
          &:hover {
            opacity: 0.8;
          }
        }
      `,
    }

    return variants[variant]
  }}
`

const Badge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  background-color: ${({ theme }) =>
    addAlphaToColor(theme.colors.foreground.default, 10)};
  color: ${({ theme }) => theme.colors.foreground.default};
  margin-left: 0.5rem;
`

/**
 * Loading
 */
const rotate360 = keyframes`
  from { transform: rotate(0deg) }
  to { transform: rotate(360deg) }
`

const ButtonLoader = styled.div<{ variant: ButtonVariant }>`
  box-sizing: border-box;

  width: 1rem;
  height: 1rem;
  border-radius: 100%;

  position: absolute;
  top: calc(50% - 0.5rem);
  left: calc(50% - 0.5rem);

  border: 2px solid
    ${({ theme, variant }) => {
      const colors = {
        solid: theme.colors.background.default,
        secondary: theme.colors.foreground.default,
        accent: theme.colors.background.default,
        ghost: theme.colors.foreground.default,
        text: theme.colors.foreground.default,
      }

      return colors[variant]
    }};

  border-left-color: transparent;
  border-top-color: transparent;

  animation: ${rotate360} 2s linear infinite;
`
