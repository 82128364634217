import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

import useInterval from 'lib/hooks/useInterval'

import { getKey } from './object'

const whitelist = ['text', 'paragraph', 'quote', 'tagline', 'description']

const getAllKeysAndValues = (obj: unknown): Array<[string, string] | null> => {
  if (typeof obj !== 'object' || obj === null) return []

  let res: Array<[string, string] | null> = []
  if (Array.isArray(obj)) {
    res = res.concat(...obj.map((item) => getAllKeysAndValues(item)))
  } else {
    Object.keys(obj).forEach((key) => {
      const value = getKey(obj, key)
      if (
        (typeof value !== 'object' || value === null) &&
        typeof value === 'string'
      ) {
        res.push([key, value])
      } else {
        res = res.concat(getAllKeysAndValues(value))
      }
    })
  }

  return res
}

type SearchContentReturnType = {
  index: number
  text: string
  cutText: string
  key: string
}

export const searchContent = (
  content: unknown,
  searchTerm: string
): Array<SearchContentReturnType> => {
  const contentItems = getAllKeysAndValues(content)
  const res: Array<SearchContentReturnType> = []

  contentItems.forEach((item) => {
    if (item === null) return
    const [key, value] = item
    const index = value.toLowerCase().indexOf(searchTerm.toLowerCase())

    if (whitelist.includes(key) && index !== -1) {
      res.push({
        index,
        text: value,
        cutText: `${index > 0 ? '...' : ''}${value.slice(index)}`,
        key,
      })
    }
  })

  return res
}

const getValidElements = (searchTerm: string | null) => {
  if (typeof window === 'undefined') return null

  // Search for all valid tags
  const htmlTagElements = document?.querySelectorAll(
    'h1, h2, h3, h4, h5, h6, p, span, b'
  )

  // Creates an array with the content of the element that matches the search
  const elements: Array<Element> = []
  htmlTagElements.forEach((element: Element) => {
    const regex = new RegExp(`(${searchTerm})(?![^<]*?>)`, 'gi')

    if (element.innerHTML.match(regex)) {
      elements.push(element)
    }
  })

  if (!elements) return null

  return elements
}

// Finds the first element that matches the search term and scrolls to it
const scrollToSearchedElement = (element: Element) => {
  window.scrollBy(0, element.getBoundingClientRect().top - 100)
}

// Highlights the every element that matches the search term
const highlightSearchedElement = (
  searchTerm: string | null,
  elements: Element[]
) => {
  const regex = new RegExp(`(${searchTerm})(?![^<]*?>)`, 'gi')

  const getHighlightedElements =
    document.getElementsByClassName('tfp-highlight')
  const highlightedElements = Array.from(getHighlightedElements)

  // Remove all existing highlights before adding new ones
  highlightedElements.forEach((element) => {
    if (element.innerHTML !== searchTerm) {
      element.outerHTML = element.innerHTML
    }
  })

  elements.forEach((element: Element): void | null => {
    const tfpHighlight = new RegExp('tfp-highlight')
    const elementHasHighlight = element.outerHTML.match(tfpHighlight)

    if (elementHasHighlight) return null

    element.outerHTML = element.outerHTML.replace(
      regex,
      `<mark class="tfp-highlight">$1</mark>`
    )
  })
}

export const useSearchAndScroll = (search: string | null): void => {
  const [hasReplaced, setHasReplaced] = useState(false)
  const [searchTry, setSearchTry] = useState(0)
  const searchTryTimeout = searchTry >= 5
  const { asPath } = useRouter()

  // Resets when page changes
  useEffect(() => {
    setHasReplaced(false)
    setSearchTry(0)
  }, [asPath])

  useInterval(
    () => {
      const validElements = getValidElements(search)
      setSearchTry(searchTry + 1)

      if (validElements && validElements.length !== 0) {
        scrollToSearchedElement(validElements[0])
        highlightSearchedElement(search, validElements)
        setHasReplaced(true)
      }
    },
    hasReplaced || !search || searchTryTimeout ? null : 500
  )
}
