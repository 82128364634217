import { createContext, useContext } from 'react'
import { ISbStoryData } from 'storyblok-js-client'

import { Breadcrumb } from 'components/ui/Breadcrumbs'
import {
  AlertStoryblok,
  ConfigStoryblok,
  FloatingButtonsStoryblok,
} from 'lib/storyblok/types'

import { EntriesData } from '../api'

export type DataContextType = {
  regions: { code: string; name: string }[] | null
  storyId: number | null
  storyUuid: string | null
  config: ISbStoryData<ConfigStoryblok> | null
  alert: ISbStoryData<AlertStoryblok> | null
  avoidAlerts: boolean
  floating: ISbStoryData<FloatingButtonsStoryblok> | null
  alternates: string[]
  isSubSite: boolean
  latestEntriesData?: EntriesData | null
  allEntriesData?: EntriesData | null
  breadcrumbs?: Breadcrumb[] | null
}

export const DataContext = createContext<DataContextType>({
  regions: null,
  storyId: null,
  storyUuid: null,
  config: null,
  alert: null,
  avoidAlerts: false,
  floating: null,
  alternates: [],
  isSubSite: false,
  latestEntriesData: null,
  allEntriesData: null,
  breadcrumbs: null,
})

export const useDataContext = (): DataContextType => {
  const context = useContext(DataContext)

  if (context === undefined && process.env.NODE_ENV !== 'production') {
    throw new Error('useDataContext must be used within a DataContext.Provider')
  }

  return context
}
