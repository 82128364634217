import NextImage from 'next/image'
import { useRouter } from 'next/router'
import styled from 'styled-components'

import { CMSLink } from 'components/blocks/deprecated/CMSLink'
import { Container } from 'components/blocks/sections/directories/AllEntries/ServiceEntries'
import { Link } from 'components/ui/deprecated/Link'
import { OldButton } from 'components/ui/deprecated/OldButton'
import { Text } from 'components/ui/deprecated/Text'
import { Theme } from 'components/ui/deprecated/Theme'
import { DYNAMIC_PHONE_NUMBER_CLASSNAME } from 'lib/constants'
import { useDataContext } from 'lib/contexts/dataContext'
import { useTranslation } from 'lib/i18n'
import { TextLinkStoryblok } from 'lib/storyblok/types'
import { addAlphaToColor, dashedLine } from 'lib/style'
import {
  hasValidLink,
  textByLine,
  getStoryblokImageAttributes,
} from 'lib/utils/content'
import { GTMEvents, trackEvent } from 'lib/utils/gtm'

type Props = {
  showRegionModal: () => void
}

export const Footer = ({ showRegionModal }: Props): JSX.Element | null => {
  const {
    query: { lang },
  } = useRouter()
  const { i18n } = useTranslation()
  const { config, regions } = useDataContext()

  const {
    content: {
      open_hours,
      phone_number,
      isDynamic,
      contact_link,
      address,
      facebook_link,
      instagram_link,
      linkedin_link,
      twitter_link,
      youtube_link,
      portal_link,
    },
  } = config || { content: {} }

  const currRegion = regions?.find((region) => region.code === lang)

  if (!config || !config?.content) {
    return null
  }

  const { footer_logo, footer_links, subfooter_links } = config.content

  return (
    <Theme colorTheme="dark-blue">
      <Wrapper>
        <Container>
          <Content>
            <FirstColumn>
              {footer_logo && footer_logo.filename && (
                <LogoHolder>
                  <NextImage
                    {...getStoryblokImageAttributes(
                      footer_logo,
                      '112x0/smart/filters:format(webp)'
                    )}
                  />
                </LogoHolder>
              )}
              <div>
                <Text as="p" color="palette.beige.60" className="mr-22">
                  {i18n('questions')}
                </Text>

                {phone_number && (
                  <Link
                    className={isDynamic ? DYNAMIC_PHONE_NUMBER_CLASSNAME : ''}
                    onClick={() => {
                      trackEvent(GTMEvents.Call, {
                        'tfp-phone-number': phone_number,
                      })
                    }}
                    href={`tel:${phone_number
                      .replace('+', '00')
                      .replace(/\D+/g, '')}`}
                  >
                    {phone_number}
                  </Link>
                )}
                {phone_number && hasValidLink(contact_link) && (
                  <Text
                    as="span"
                    color="palette.beige.60"
                    className="my-0 mx-1"
                  >
                    {i18n('or')}
                  </Text>
                )}
                {contact_link && hasValidLink(contact_link) && (
                  <CMSLink linkStyle="text" href={contact_link}>
                    {i18n('messageUs')}
                  </CMSLink>
                )}

                {open_hours && (
                  <>
                    {textByLine(open_hours, (part) => (
                      <Text as="p" style={{ minHeight: 24 }}>
                        {part}
                      </Text>
                    ))}
                  </>
                )}
              </div>

              {address && (
                <FirstColumnContent>
                  {textByLine(address, (part) => (
                    <Text as="p" style={{ minHeight: 24 }}>
                      {part}
                    </Text>
                  ))}
                </FirstColumnContent>
              )}
            </FirstColumn>

            <FooterNav>
              {footer_links?.map((column) => (
                <FooterColumn key={column._uid}>
                  {column.title && (
                    <Text
                      as="p"
                      color="foreground.default"
                      className="opacity-60 mb-6"
                    >
                      {column.title}
                    </Text>
                  )}
                  <ul>
                    {column.links?.map((link: TextLinkStoryblok) => (
                      <FooterItem key={link._uid}>
                        <CMSLink linkStyle="text" href={link.link}>
                          {link.label}
                        </CMSLink>
                      </FooterItem>
                    ))}
                  </ul>
                </FooterColumn>
              ))}
            </FooterNav>
          </Content>
          {/* MIDDLE */}
          <Middle>
            {facebook_link ||
            youtube_link ||
            instagram_link ||
            linkedin_link ||
            twitter_link ? (
              <Social>
                <SocialTitle as="p" color="background.secondaryBlue">
                  {i18n('socialFollowUs')}
                </SocialTitle>
                <SocialList>
                  {[
                    facebook_link,
                    youtube_link,
                    instagram_link,
                    linkedin_link,
                    twitter_link,
                  ].map((link, i) => {
                    const src = [
                      '/assets/facebook.png',
                      '/assets/youtube.png',
                      '/assets/instagram.png',
                      '/assets/linkedin.png',
                      '/assets/twitter.png',
                    ]
                    if (link) {
                      return (
                        <SocialItem key={i}>
                          <a
                            href={link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={src[i]}
                              alt={src[i]
                                .replace('/assets/', '')
                                .replace('.png', '')}
                            />
                          </a>
                        </SocialItem>
                      )
                    }
                    return null
                  })}
                </SocialList>
              </Social>
            ) : (
              // empty div to align region selector to the right
              <div />
            )}
            <LangAndPortal>
              {currRegion && (
                <LangAndPortalItem>
                  <OldButton
                    size="micro"
                    variant="text"
                    leftIcon="world"
                    rightIcon="caret-down"
                    onClick={() => showRegionModal()}
                  >
                    {currRegion.name}
                  </OldButton>
                </LangAndPortalItem>
              )}
              {portal_link && (
                <LangAndPortalItem>
                  <Link
                    href={portal_link}
                    target="_blank"
                    rel="noopener noreferrer"
                    leftIcon="calendar"
                    rightIcon="arrow-right"
                    onClick={() => {
                      trackEvent(GTMEvents.PortalClick, {
                        element: 'Footer',
                      })
                    }}
                  >
                    {i18n('patientPortal')}
                  </Link>
                </LangAndPortalItem>
              )}
            </LangAndPortal>
          </Middle>
          {/* BOTTOM */}
          <Bottom>
            <Text as="p" variant="fourteen">
              {i18n('brand') || '© The Fertility Partnership, Inc'}
            </Text>
            <BottomNav>
              {subfooter_links?.map((link) => (
                <BottomNavItem key={link._uid}>
                  <CMSLink linkStyle="text" href={link.link}>
                    {link.label}
                  </CMSLink>
                </BottomNavItem>
              ))}
            </BottomNav>
          </Bottom>
        </Container>
      </Wrapper>
    </Theme>
  )
}

const Wrapper = styled.footer`
  background-color: ${({ theme }) => theme.colors.background.default};
`

const LogoHolder = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  margin-bottom: 2.5rem;
  flex-shrink: 0;

  img {
    height: fit-content;
    width: 5.75rem;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;

  padding: 2.5rem 0;
  ${({ theme }) =>
    dashedLine('top', addAlphaToColor(theme.colors.foreground.default, 60))};

  ${({ theme }) => theme.media.md} {
    flex-direction: row;
  }
`

const FirstColumn = styled.div`
  max-width: 32rem;
  flex-shrink: 0;

  p,
  a {
    line-height: 1.5;
  }

  margin-bottom: 2rem;

  ${({ theme }) => theme.media.md} {
    margin-bottom: 0;
    margin-right: 6rem;
  }
`

const FirstColumnContent = styled.div`
  margin-top: 2rem;
`

const FooterNav = styled.nav`
  width: 100%;
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));
`

const FooterColumn = styled.div`
  padding-top: 1rem;
`

const FooterItem = styled.li`
  padding: 0.5rem 0;
`

const Middle = styled.div`
  font-size: 0.875rem;

  ${({ theme }) => theme.media.md} {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

const Social = styled.div`
  ${({ theme }) => theme.media.md} {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`

const SocialTitle = styled(Text)`
  padding: 1rem 0;
`

const SocialList = styled.ul`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  ${({ theme }) => theme.media.md} {
    margin-bottom: 0;
  }
`

const SocialItem = styled.li`
  &:not(:last-of-type) {
    margin-right: 1rem;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  img {
    width: 1.5rem;
    height: 1.5rem;
  }
`

const LangAndPortal = styled.div`
  display: flex;
  flex-direction: column;
  white-space: nowrap;

  ${({ theme }) => theme.media.sm} {
    flex-direction: row;
    align-items: center;
  }

  ${({ theme }) => theme.media.md} {
    border-top: none;
  }
`

const LangAndPortalItem = styled.div`
  display: flex;
  align-items: center;

  ${({ theme }) =>
    dashedLine('top', addAlphaToColor(theme.colors.foreground.default, 20))};

  padding: 1rem 0;

  // make portal link a little similar to lang switcher button
  a {
    display: inline-flex;
    align-items: center;
    height: 1.5rem;
    padding: 0.075rem 0.25rem;
  }

  ${({ theme }) => theme.media.sm} {
    border-top: 0;

    &:last-of-type {
      padding-left: 1rem;
      margin-left: 1rem;
      ${({ theme }) =>
        dashedLine(
          'left',
          addAlphaToColor(theme.colors.foreground.default, 20)
        )};
    }
  }

  ${({ theme }) => theme.media.md} {
    padding-left: 1rem;
    margin-left: 1rem;
    ${({ theme }) =>
      dashedLine('left', addAlphaToColor(theme.colors.foreground.default, 20))};
  }
`

const Bottom = styled.div`
  white-space: nowrap;

  ${({ theme }) =>
    dashedLine('top', addAlphaToColor(theme.colors.foreground.default, 20))};
  color: ${({ theme }) => addAlphaToColor(theme.colors.foreground.default, 60)};

  padding: 1rem 0;

  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;

  font-size: 0.875rem;

  ${({ theme }) => theme.media.lg} {
    flex-direction: row;
    align-items: center;
  }
`

const BottomNav = styled.ul`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  margin-top: 0.5rem;

  ${({ theme }) => theme.media.sm} {
    flex-direction: row;
    flex-wrap: wrap;
  }

  ${({ theme }) => theme.media.lg} {
    margin-top: 0;
    margin-left: 4rem;
  }
`

const BottomNavItem = styled.li`
  padding: 0.25rem 0;

  ${({ theme }) => theme.media.sm} {
    margin-right: 1rem;
  }

  ${({ theme }) => theme.media.lg} {
    margin-right: 0;
    margin-left: 1rem;
  }
`
