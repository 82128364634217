import React, { InputHTMLAttributes } from 'react'

import { Icon } from '../Icon'
import { TextField } from './TextField'

type Props = InputHTMLAttributes<HTMLInputElement>

export const SearchInput = (props: Props): JSX.Element => {
  return (
    <div className="relative flex-1">
      <TextField
        variant="input"
        name="search"
        {...props}
        style={{ paddingLeft: '3rem', ...props.style }}
      />

      <Icon
        icon="search"
        className="absolute left-4 top-1/2 -translate-y-1/2 pointer-events-none"
      />
    </div>
  )
}
