import { storyblokEditable } from 'lib/storyblok/storyblokEditable'
import { RichTextMediaStoryblok } from 'lib/storyblok/types'
import { isSourceType } from 'lib/utils/isSourceType'
import { cn } from 'lib/utils/tailwind'

import { StoryblokImage } from '../StoryblokImage'
import { StoryblokVideo } from '../StoryblokVideo'

type Props = {
  block: RichTextMediaStoryblok
}

export const RichTextMedia = ({ block }: Props) => {
  const { size, source } = block

  return (
    <div
      className={cn(
        'w-full text-foreground flex flex-col gap-4 my-10 mx-auto px-5 lg:px-0',
        size === 'regular' ? 'max-w-rich-text' : 'max-w-[994px]'
      )}
      {...storyblokEditable(block)}
    >
      {isSourceType(source.filename, 'image') ? (
        <div className="relative h-full w-full aspect-square rounded-6 overflow-hidden">
          <StoryblokImage
            fill
            asset={source}
            width={undefined}
            height={undefined}
          />
        </div>
      ) : (
        <div className="relative w-fit rounded-8 aspect-auto overflow-hidden">
          <StoryblokVideo asset={source} />
        </div>
      )}

      <span className="flex text-sixteen text-foreground/80">
        {source.title}
      </span>
    </div>
  )
}
